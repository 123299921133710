import { fileModule } from "@/modules/file";
import { logger } from "@/modules/logger";
import { toastModule } from "@/modules/toast";
import { uuidModule } from "@/modules/uuid";
import { FC, useCallback } from "react";

export const SettingsDebugSandboxContent: FC = () => {
  const sandboxFileUploader: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    async event => {
      const fileData = event.target.files?.[0];

      if (fileData) {
        const fileId = uuidModule.generate();

        logger.info({
          message: "[sandboxFileUploader] Uploading...",
          info: {
            fileData: fileData.name,
          },
        });

        const { signedUrl } = await fileModule.upload({
          fileId,
          fileName: fileData.name,
          fileBlob: fileData,
          fileMimeType: fileData.type,
        });

        logger.info({
          message: "[sandboxFileUploader] Uploaded.",
          info: {
            signedUrl,
          },
        });

        toastModule.triggerToast({
          content: "File uploaded successfully",
        });
      }
    },
    []
  );

  return (
    <>
      <input type="file" accept="image/*" onChange={sandboxFileUploader} />
    </>
  );
};
