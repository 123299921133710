import { computed, makeObservable, observable, action, reaction } from "mobx";
import {
  groupLens,
  TimelineWithInboxStatusGroup,
  TimelineWithInboxStatusItemFull,
} from "@/modules/timeline";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { MdsDropdownContentList, MdsDropdownItemKind } from "@/design-system/components/dropdown";
import { MdsItemDropdown } from "@/design-system/constants/items/types";
import { MdsIconKind } from "@/design-system/components/icon";
import { MdsItemListRowData, MdsItemListRowType } from "@/design-system/components/item-list/types";
import { actions } from "@/actions";
import { ShareSheetEntityKind } from "@/components/modal/share-sheet/types";
import { ListStateObservable } from "@/store/pages/ListStateObservable";
import { css, cx } from "@/domains/emotion";
import { OnClick } from "@/design-system/constants/handlers/types";
import { AccountProfileImage } from "@/components/layout/components/account-profile/AccountProfileImage";
import { MdsButton, MdsButtonVariant, MdsButtonSize } from "@/design-system/components/button";
import {
  MdsButtonShape,
  MdsButtonIconPosition,
  MdsSelectionVariant,
} from "@/design-system/components/button/types";
import { filter, orderBy } from "lodash-es";
import { DeleteSharedNotesModalStore } from "@/components/modal/delete-shared-notes/DeleteSharedNoteModalStore";
import { MdsIcon } from "@/design-system/components/icon/MdsIcon";
import { AddToCollectionModalStore } from "@/components/modal/add-to-collection/AddToCollectionModalStore";
import { ShareSheetModalStore } from "@/components/modal/share-sheet/ShareSheetModalStore";
import { INoteObservable } from "@/store/note";
import { ProfileKind, ProfileSize } from "@/components/layout/components/account-profile";
import { LensKind, SortByKind } from "@/modules/lenses/types";
import { lensModule, notesLensModule } from "@/modules/lenses";
import localDb from "@/domains/local-db";
import { getRowForNoteId } from "@/store/note/getRowForNoteId";

export interface NotesListPageParams {
  sortBy: SortByKind;
  lens: LensKind;
}
const DEFAULT_SORT_BY = SortByKind.LastModified;
const DEFAULT_LENS = LensKind.All;

export interface NotesListPage {
  supportsAddToCollectionListAction: boolean;
  listState: ListStateObservable;
  hasNoItems: boolean;
  params: NotesListPageParams;
  lens: LensKind;
  sortBy: SortByKind;
}

export class NotesListPageStore extends AppSubStore implements NotesListPage {
  params: NotesListPageParams;
  supportsAddToCollectionListAction = true;
  supportsMoveToTrashListAction = true;
  listState: ListStateObservable;
  searchQuery = "";
  addToCollectionModal: AddToCollectionModalStore;
  deleteSharedNotesModal: DeleteSharedNotesModalStore;
  shareSheetModal: ShareSheetModalStore;

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);

    this.params = { sortBy: DEFAULT_SORT_BY, lens: DEFAULT_LENS };
    this.addToCollectionModal = new AddToCollectionModalStore(injectedDeps, {});
    this.deleteSharedNotesModal = new DeleteSharedNotesModalStore(injectedDeps, {});
    this.shareSheetModal = ShareSheetModalStore.forAppStore({ appStore: this.store });
    this.listState = new ListStateObservable({ ...injectedDeps, listStateProvider: this });

    makeObservable<this, "generateItemDropdown">(this, {
      getGroupedHomeLensItems: false,
      getItemRows: false,

      supportsAddToCollectionListAction: observable,
      supportsMoveToTrashListAction: observable,
      searchQuery: observable,
      listState: false,
      addToCollectionModal: false,
      deleteSharedNotesModal: false,
      shareSheetModal: false,

      isLoading: computed,
      uiFilters: computed,
      generateItemDropdown: false,
      itemRows: computed,
      orderedItemIds: computed,
      hasNoItems: computed,

      // PARAMS
      params: observable,
      setParams: action,
      setLens: action,
      setSortBy: action,
      sortOptions: computed,
      lens: computed,
      sortBy: computed,
      sortLabel: computed,
      initialize: action,
    });

    reaction(
      () => this.params,
      () => localDb.settings.setNotesListPageParams(this.params)
    );
  }

  public setParams(params: Partial<NotesListPageParams>) {
    const lens = params.lens ?? this.params.lens;
    const sortBy = params.sortBy ?? this.params.sortBy;
    this.params = { lens, sortBy };
  }

  setLens = (lens: LensKind) => {
    this.setParams({ lens });
  };

  setSortBy = ({ itemId }: { itemId: string }) => {
    this.setParams({ sortBy: itemId as SortByKind });
  };

  get lens() {
    return this.params.lens;
  }

  get sortBy() {
    return this.params.sortBy;
  }

  get sortLabel(): string {
    return lensModule.sortKindLabelMap[this.sortBy];
  }

  get sortOptions(): MdsDropdownContentList {
    return {
      items: [
        {
          id: "sort-by-divider",
          kind: MdsDropdownItemKind.Detail,
          text: "Sort by",
        },
        {
          id: SortByKind.LastCreated,
          kind: MdsDropdownItemKind.Button,
          label: "Last created",
          isChecked: this.sortBy === "LAST_CREATED",
          onClick: this.setSortBy,
        },
        {
          id: SortByKind.LastModified,
          kind: MdsDropdownItemKind.Button,
          label: "Last modified",
          isChecked: this.sortBy === "LAST_MODIFIED",
          onClick: this.setSortBy,
        },
        {
          id: SortByKind.LastViewed,
          kind: MdsDropdownItemKind.Button,
          label: "Last viewed",
          isChecked: this.sortBy === "LAST_VIEWED",
          onClick: this.setSortBy,
        },
      ],
    };
  }

  get isLoading() {
    /** Currently, always false (we serve data from the grouped lenses in-memory.) */
    return false;
  }

  get uiFilters() {
    const filters: { id: string; kind: string; content: React.ReactNode }[] = [];

    const iconLabel = (icon: React.ReactNode, label: string) => () => (
      <div className={cx(compositeLabelStyles)}>
        {icon}
        <span>{label}</span>
      </div>
    );
    const addButton = (
      label: string,
      icon: React.ReactNode,
      onClick: OnClick,
      kind: string,
      id: string,
      selected: boolean
    ) => {
      filters.push({
        id,
        kind,
        content: (
          <div>
            <MdsButton
              className={lensKindButtonStyles}
              shape={MdsButtonShape.Round}
              variant={MdsButtonVariant.Outlined}
              size={MdsButtonSize.Medium}
              label={iconLabel(icon, label)}
              iconPosition={MdsButtonIconPosition.Right}
              iconKind={selected ? MdsIconKind.Exit : undefined}
              onClick={onClick}
              isSelected={selected}
              selectionVariant={MdsSelectionVariant.Accent}
            />
          </div>
        ),
      });
    };

    if (this.lens !== LensKind.SharedWithMe) {
      addButton(
        "Added by me",
        <AccountProfileImage
          profile={{
            kind: ProfileKind.Me,
          }}
          size={ProfileSize.Small}
          className={negativeMarginStyles}
        />,
        () => this.setLens(this.lens === LensKind.AddedByMe ? LensKind.All : LensKind.AddedByMe),
        "OWNED_BY_SPACE_ACCOUNT",
        this.store.account.myAccountId,
        this.lens === LensKind.AddedByMe
      );
    }

    if (this.lens !== LensKind.AddedByMe) {
      addButton(
        "Shared with me",
        <MdsIcon className="mds-btn-icon" kind={MdsIconKind.Shared} />,
        () =>
          this.setLens(this.lens === LensKind.SharedWithMe ? LensKind.All : LensKind.SharedWithMe),
        "OWNED_BY_SPACE_ACCOUNT",
        LensKind.SharedWithMe,
        this.lens === LensKind.SharedWithMe
      );
    }

    return filters;
  }

  private generateItemDropdown({
    noteObservable,
  }: {
    noteObservable: INoteObservable;
  }): MdsItemDropdown {
    return {
      items: [
        {
          id: `share-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: MdsIconKind.Share,
          label: "Share",
          onClick: () =>
            this.shareSheetModal.open({
              id: noteObservable.id,
              entityKind: ShareSheetEntityKind.Note,
            }),
        },
        {
          id: `copy-link-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: MdsIconKind.Copy,
          label: "Copy link",
          onClick: () => actions.copyNoteLinkToClipboard({ noteId: noteObservable.id }),
        },
        {
          id: "divider-1",
          kind: MdsDropdownItemKind.Divider,
        },
        {
          id: `favorite-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: noteObservable.isFavorited ? MdsIconKind.ThumbtackSolid : MdsIconKind.Thumbtack,
          label: noteObservable.isFavorited ? "Unpin" : "Pin",
          onClick: () => noteObservable.toggleFavorite(),
        },
        {
          id: `add-to-collection-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: MdsIconKind.Collection,
          label: "Organize",
          onClick: () => this.addToCollectionModal.open({ noteIds: [noteObservable.id] }),
        },
        {
          id: "divider-2",
          kind: MdsDropdownItemKind.Divider,
        },
        {
          id: `move-to-trash-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: MdsIconKind.Trash,
          label: "Delete",
          onClick: () => {
            if (noteObservable.isShared) {
              this.deleteSharedNotesModal.open(noteObservable);
            } else {
              noteObservable.moveToTrash();
            }
          },
        },
      ],
    };
  }

  get itemRows(): MdsItemListRowData[] {
    return this.getItemRows(this.lens, this.sortBy);
  }

  getItemRows(lens: LensKind, sortBy: SortByKind, inMainPanel = true): MdsItemListRowData[] {
    const output: MdsItemListRowData[] = [];
    for (const group of this.getGroupedHomeLensItems(lens, sortBy)) {
      // Add header for each section
      if (inMainPanel) {
        output.push({
          type: MdsItemListRowType.SectionHeader,
          key: group.title,
          payload: { title: group.title },
        });
      }
      // Add each item afterwards
      for (const item of group.items) {
        if (item.kind === "full") {
          const row = getRowForNoteId({
            dateTime: item.dateTime,
            generateItemDropdown: this.generateItemDropdown,
            inMainPanel,
            listState: this.listState,
            noteId: item.item.id,
            store: this.store,
          });
          if (!row) continue;

          output.push(row);
        }
      }
    }

    output.push({
      type: MdsItemListRowType.Padding,
      key: "padding",
      payload: { height: 50 },
    });

    return output;
  }

  get orderedItemIds() {
    return this.itemRows.map(row => row.key);
  }

  getGroupedHomeLensItems(lens: LensKind, sortBy: SortByKind): TimelineWithInboxStatusGroup[] {
    const sortedItems = ((): TimelineWithInboxStatusItemFull[] => {
      if (sortBy !== SortByKind.LastViewed) {
        return orderBy(
          this.store.notes.allNotes
            .filter(note => note.isAvailable)
            .filter(notesLensModule.filterFunctionMap[lens])
            .map(note => ({
              kind: "full",
              item: note,
              dateTime:
                this.sortBy === SortByKind.LastCreated
                  ? new Date(note.createdAt)
                  : new Date(note.modifiedAt),
            })),
          e => e.dateTime,
          "desc"
        );
      }
      return filter(this.store.recentItems.sortedRecentNotesInteractedWithByMe).map(recentItem => ({
        kind: "full",
        item: recentItem as INoteObservable,
        dateTime: new Date(recentItem.lastViewedAt),
      }));
    })();
    return groupLens(sortedItems);
  }

  get hasNoItems() {
    return this.itemRows.filter(row => row.type === MdsItemListRowType.Item).length === 0;
  }

  async initialize() {
    const params = await localDb.settings.getNotesListPageParams();
    this.setParams({
      lens: params.lens ?? DEFAULT_LENS,
      sortBy: params.sortBy ?? DEFAULT_SORT_BY,
    });
  }
}

const lensKindButtonStyles = css({
  whiteSpace: "nowrap",
  ".mds-btn-label": {
    maxWidth: 250,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  ".mds-btn-icon": {
    height: "12px",
    width: "12px",
  },
});

const compositeLabelStyles = css({
  display: "flex",
  alignItems: "center",
  gap: "4px",
});

const negativeMarginStyles = css({
  margin: "-2px 0",
});
