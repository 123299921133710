import { useMemo } from "react";
import { SearchSuggestion } from "@/domains/search/suggestions/types";
import { searchSort } from "@/modules/search-sort";
import uFuzzy from "@leeoniya/ufuzzy";

export class SearchSuggestions {
  private fuzzy = new uFuzzy();

  search(query: string, items: SearchSuggestion[]): SearchSuggestion[] {
    const haystack = items.map(item => item.label);
    const indexes = this.fuzzy.filter(haystack, query);

    return indexes?.map(index => items[index]).sort(searchSort) || [];
  }
}

export const useSearchSuggestions = () => {
  const searchSuggestions = useMemo(() => new SearchSuggestions(), []);

  return searchSuggestions;
};
