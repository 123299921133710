import { generateShortDateString } from "@/domains/date/date";
import { LensKind, SortByKind } from "@/modules/lenses/types";
import { INoteObservable } from "@/store/note";

export const notesLensSortFunctionMap: {
  [key in SortByKind]: (a: INoteObservable, b: INoteObservable) => number;
} = {
  [SortByKind.LastModified]: (a: INoteObservable, b: INoteObservable) =>
    new Date(b.modifiedAt).getTime() - new Date(a.modifiedAt).getTime(),
  [SortByKind.LastCreated]: (a: INoteObservable, b: INoteObservable) =>
    new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  [SortByKind.LastViewed]: (a: INoteObservable, b: INoteObservable) =>
    new Date(b.spaceAccountNote?.lastViewedAt || b.createdAt).getTime() -
    new Date(a.spaceAccountNote?.lastViewedAt || a.createdAt).getTime(),
  [SortByKind.Alphabetical]: (a: INoteObservable, b: INoteObservable) =>
    a.title.localeCompare(b.title),
};

export const notesLensDateLabelMap: {
  [key in SortByKind]: (note: INoteObservable) => string;
} = {
  [SortByKind.LastModified]: note => generateShortDateString(note.modifiedAt),
  [SortByKind.LastCreated]: note => generateShortDateString(note.createdAt),
  [SortByKind.LastViewed]: note =>
    generateShortDateString(note.spaceAccountNote?.lastViewedAt || note.createdAt),
  [SortByKind.Alphabetical]: note => generateShortDateString(note.modifiedAt),
};

export const notesLensFilterFunctionMap: {
  [key in LensKind]: (note: INoteObservable) => boolean;
} = {
  [LensKind.All]: () => true,
  [LensKind.AddedByMe]: note => note.isOwnedByMe,
  [LensKind.SharedWithMe]: note => !note.isOwnedByMe,
  [LensKind.Unorganized]: note => note.collectionList?.allCollections.length === 0,
};
