import { CollectionObservable } from "@/store/collections/CollectionObservable";

export enum CollectionItemMode {
  SELECT = "select",
  CREATE = "create",
}

export enum Direction {
  UP = "up",
  DOWN = "down",
}

export interface SimpleCollection {
  id: string;
  title: string;
  noteCount: number;
  description?: string;
  isShared?: boolean;
}

export const makeSimpleCollection = (collection: CollectionObservable): SimpleCollection => {
  return {
    id: collection.id,
    title: collection.title ?? "",
    noteCount: collection.itemList.size,
    description: collection.description,
    isShared: collection.isShared,
  };
};
