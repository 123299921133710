import { ChatMessageSectionValue } from "@/domains/chat";
import styled from "@emotion/styled";
import { MemCommonMarkdown, syntaxTreeModule } from "@mem-labs/common-editor";

interface Props {
  data: ChatMessageSectionValue;
}

export function UserMessage({ data }: Props): JSX.Element {
  // User messages are "MDX", so we convert them to standard "Markdown" before rendering them.
  const { content } = data;

  const markdownContent = syntaxTreeModule.convertMdxToMarkdown({
    mdxContent: content,
  });

  return <Markdown className="user-message">{markdownContent}</Markdown>;
}

const Markdown = styled(MemCommonMarkdown)({
  width: "100%",
});
