// FindInPageOverlay.tsx
import React, { FC, useState, useEffect } from "react";
import { usePublicAppStore } from "@/store";
import { FindInPageOverlayContent } from "./FindInPageOverlayContent";
import { observer } from "mobx-react-lite";

export const FindInPageOverlay: FC = observer(() => {
  const { publicStore } = usePublicAppStore();

  if (!publicStore.interface.isFindInPageOpen) {
    return null;
  }

  return <FindInPageOverlayContent />;
});
