import { LogInPage } from "@/pages/log-in";
import { LandingPage } from "@/pages/landing";
import { RouteObject } from "react-router-dom";
import { LogOutPage } from "@/pages/log-out";
import { GoogleOAuthRedirectPage } from "@/pages/google-oauth-redirect";
import { GoogleOAuthRedirectDesktopPage } from "@/pages/google-oauth-redirect-desktop";
import { RootOutlet } from "@/app/outlets/RootOutlet";
import { SignUpPage } from "@/pages/sign-up";
import { StoreProviderOutlet } from "@/app/outlets/StoreProviderOutlet";
import { Navigate } from "react-router-dom";
import { GuestNotesViewPage, StandardNotesViewPage } from "@/pages/notes-view/NotesViewPage";
import { StandardModeAppLayoutOutlet } from "@/app/outlets/layout/StandardModeAppLayoutOutlet";
import { AdminPage } from "@/pages/admin";
import { RequireAdminAuthOutlet } from "@/app/outlets/AdminOutlet";
import { TrashPage } from "@/pages/trash";
import { CollectionsListPage } from "@/pages/collections";
import { CollectionsViewPage } from "@/pages/collections-view";
import { IntegrationsPage } from "@/pages/integrations";
import { SearchPage } from "@/pages/search";
import { ChatPage } from "@/pages/chat";
import { SettingsLayoutOutlet } from "@/app/outlets/SettingsLayoutOutlet";
import {
  SettingsEmailPreferencesPage,
  SettingsAccountManagementPage,
  SettingsSubscriptionManagementPage,
  SettingsExportsPage,
  SettingsDebugAccountPage,
} from "@/pages/settings";
import { NotFoundPage } from "@/pages/not-found";
import { appRoutes } from "@/app/router/appRoutes";
import { PageElementRouter } from "@/app/outlets/PageElementRouter";
import { RequireStandardModeOutlet } from "@/app/outlets/RequireStandardModeOutlet";
import { RequireGuestModeOrStandardModeOutlet } from "@/app/outlets/RequireGuestModeOrStandardModeOutlet";
import { GuestModeAppLayoutOutlet } from "@/app/outlets/layout/GuestModeAppLayoutOutlet";
import { LogInEmailPasswordPage } from "@/pages/log-in-email-password";
import { StandardModePadding } from "@/app/outlets/StandardModePadding";
import { NotesListPage } from "@/pages/notes/NotesListPage";
import { SettingsAccountMigrationPage } from "@/pages/settings/SettingsAccountMigrationPage";
import { NavigateWithParams } from "@/app/router/NavigateWithParams";
import { SettingsDebugSandboxPage } from "@/pages/settings/SettingsDebugSandboxPage";

const GUEST_MODE_OR_STANDARD_MODE_ROUTES: RouteObject[] = [
  /**
   * =============================================
   *         Notes Routes
   * =============================================
   */
  {
    path: appRoutes.notesView({
      params: { noteId: ":noteId" },
    }).path,
    element: (
      <PageElementRouter
        standardModeElement={<StandardNotesViewPage />}
        guestModeElement={<GuestNotesViewPage />}
      />
    ),
  },
  {
    path: appRoutes.oldNotesView({
      params: { noteId: ":noteId" },
    }).path,
    element: (
      <NavigateWithParams
        to={({ noteId }) =>
          noteId ? appRoutes.notesView({ params: { noteId } }).path : appRoutes.notesList({}).path
        }
      />
    ),
  },
];

export const STANDARD_MODE_ROUTES: RouteObject[] = [
  {
    path: appRoutes.root({}).path,
    element: <RequireStandardModeOutlet />,
    children: [
      /**
       * =============================================
       *               Notes Routes
       * =============================================
       */
      {
        path: appRoutes.notesList({}).path,
        element: <NotesListPage />,
      },
      {
        path: appRoutes.home({}).path,
        element: <Navigate to={appRoutes.notesList({}).path} />,
      },
      {
        path: appRoutes.everything({}).path,
        element: <Navigate to={appRoutes.notesList({}).path} />,
      },
      /**
       * =============================================
       *               Trash Routes
       * =============================================
       */
      {
        path: appRoutes.trash({}).path,
        element: <TrashPage />,
      },
      /**
       * =============================================
       *               Settings Routes
       * =============================================
       */
      {
        path: appRoutes.settings({}).path,
        element: <SettingsLayoutOutlet />,
        children: [
          {
            path: appRoutes.settingsEmailPreferences({}).path,
            element: <SettingsEmailPreferencesPage />,
          },
          {
            path: appRoutes.settingsSubscriptionManagement({}).path,
            element: <SettingsSubscriptionManagementPage />,
          },
          {
            path: appRoutes.settingsAccountManagement({}).path,
            element: <SettingsAccountManagementPage />,
          },
          {
            path: appRoutes.settingsExports({}).path,
            element: <SettingsExportsPage />,
          },
          {
            path: appRoutes.settingsAccountMigration({}).path,
            element: <SettingsAccountMigrationPage />,
          },
          {
            path: appRoutes.settingsDebugAccount({}).path,
            element: <SettingsDebugAccountPage />,
          },
          {
            path: appRoutes.settingsDebugSandbox({}).path,
            element: <SettingsDebugSandboxPage />,
          },
          {
            path: appRoutes.settingsDebug({}).path,
            element: <Navigate to={appRoutes.settingsDebugAccount({}).path} />,
          },
          {
            path: appRoutes.settings({}).path,
            element: <Navigate to={appRoutes.settingsEmailPreferences({}).path} />,
          },
        ],
      },
      /**
       * =============================================
       *               Chat Routes
       * =============================================
       */
      {
        path: appRoutes.chat({}).path,
        element: <ChatPage />,
      },
      /**
       * =============================================
       *               Collections Routes
       * =============================================
       */
      {
        path: appRoutes.collections({}).path,
        element: <CollectionsListPage />,
      },
      {
        path: appRoutes.collectionsView({
          params: { collectionId: ":collectionId" },
        }).path,
        element: <CollectionsViewPage />,
      },
      /**
       * =============================================
       *               Integrations Routes
       * =============================================
       */
      {
        path: appRoutes.integrations({}).path,
        element: <IntegrationsPage />,
      },
      /**
       * =============================================
       *               Search Routes
       * =============================================
       */
      {
        path: appRoutes.search({}).path,
        element: <SearchPage />,
      },
      /**
       * =============================================
       *               Admin Routes
       * =============================================
       */
      {
        path: appRoutes.admin({}).path,
        element: <RequireAdminAuthOutlet />,
        children: [
          {
            path: appRoutes.admin({}).path,
            element: <AdminPage />,
          },
        ],
      },
    ],
  },
  {
    path: appRoutes.root({ starPattern: true }).path,
    element: (
      // Apply consistent padding for the NotFoundPage.
      <StandardModePadding>
        <NotFoundPage />
      </StandardModePadding>
    ),
  },
];

export const ALL_APP_ROUTES: RouteObject[] = [
  {
    path: appRoutes.root({}).path,
    element: <RootOutlet />,
    children: [
      /**
       * =============================================
       *                Public Routes
       * =============================================
       */
      {
        path: appRoutes.root({}).path,
        element: <LandingPage />,
      },
      {
        path: appRoutes.logInEmailPassword({}).path,
        element: <LogInEmailPasswordPage />,
      },
      {
        path: appRoutes.logIn({}).path,
        element: <LogInPage />,
      },
      {
        path: appRoutes.signUp({}).path,
        element: <SignUpPage />,
      },
      {
        path: appRoutes.logOut({}).path,
        element: <LogOutPage />,
      },
      {
        path: appRoutes.googleOAuthRedirect({}).path,
        element: <GoogleOAuthRedirectPage />,
      },
      {
        path: appRoutes.googleOAuthRedirectDesktop({}).path,
        element: <GoogleOAuthRedirectDesktopPage />,
      },
      {
        path: appRoutes.root({}).path,
        element: <StoreProviderOutlet />,
        children: [
          {
            path: appRoutes.root({}).path,
            element: <RequireGuestModeOrStandardModeOutlet />,
            children: [
              {
                path: appRoutes.root({}).path,
                element: (
                  <PageElementRouter
                    standardModeElement={<StandardModeAppLayoutOutlet />}
                    guestModeElement={<GuestModeAppLayoutOutlet />}
                  />
                ),
                children: [...GUEST_MODE_OR_STANDARD_MODE_ROUTES, ...STANDARD_MODE_ROUTES],
              },
            ],
          },
        ],
      },
    ],
  },
];
