import { Uuid } from "@/domains/global/identifiers";
import {
  GrantableSyncScopeRoleKind,
  SyncModelPermissionEntryWithStatus,
  SyncModelScope,
} from "@/domains/sync-scopes/types";
import { UNTITLED_NOTE_TITLE } from "@/domains/untitled/untitled";
import { GuestAppStore } from "@/store";
import { IContactModel } from "@/store/contacts/types";
import { INoteObservable, NoteModelData } from "@/store/note/types";
import { GrantNoteAclViaEmailAddressOperationGuestMode } from "@/store/sync/operations/notes/GrantNoteAclViaEmailAddressOperation";
import { UpdateNoteContentUsingDiffOperation } from "@/store/sync/operations/notes/UpdateNoteContentUsingDiffOperation";
import { UpdateNoteAclViaEmailAddressOperationGuestMode } from "@/store/sync/operations/notes/UpdateNoteAclViaEmailAddressOperation";
import { RevokeNoteAclViaEmailAddressOperationGuestMode } from "@/store/sync/operations/notes/RevokeNoteAclViaEmailAddressOperation";
import { SyncModelUpsertedSyncUpdate, SyncUpdateValue } from "@/store/sync/types";
import { WithGuestAppStore } from "@/store/types";
import { canWrite } from "@/store/sync/operations/canWrite";
import { filter, uniq } from "lodash-es";
import { action, computed, makeObservable, observable } from "mobx";
import {
  getPermissionsForNoteSyncModel,
  getRemotePermissionsForModel,
} from "@/store/sync/operations/helpers/permissions/getPermissionsForModel";
import { getQueuedDocumentUpdates } from "@/store/sync/operations/helpers/notes/getQueuedDocumentUpdates";
import { getGrantedCollectionIdsForPermissions } from "@/store/sync/operations/helpers/permissions/getGrantedCollectionIdsForPermissions";
import { getGrantedEmailAddressesForPermissions } from "@/store/sync/operations/helpers/permissions/getGrantedEmailAddressesForPermissions";
import { getGrantedSpaceAccountIdsForPermissions } from "@/store/sync/operations/helpers/permissions/getGrantedSpaceAccountIdsForPermissions";
import { NoteCollectionListObservable } from "@/store/collection-items/NoteCollectionListObservable";
import { SpaceAccountNoteObservable } from "@/store/recent-items/SpaceAccountNoteObservable";
import { ContactObservable } from "@/store/contacts/ContactObservable";
import { FavoriteItemObservable } from "@/store/favorite-items/FavoriteItemObservable";
import { MemCommonEditorContext, MemCommonEditorFileInfo } from "@mem-labs/common-editor";

export class GuestNoteObservable implements INoteObservable {
  store: GuestAppStore;

  id: Uuid;
  isDeleted = false;
  canAccess = true;

  remoteData: SyncUpdateValue<NoteModelData>;

  public collectionList?: NoteCollectionListObservable;

  constructor({
    id,
    data,
    store,
  }: {
    id: Uuid;
    data: SyncUpdateValue<NoteModelData>;
  } & WithGuestAppStore) {
    this.store = store;

    this.id = id;
    this.remoteData = data;

    makeObservable(this, {
      collectionList: true,
      secondaryTitle: true,
      sharedBy: true,
      trashedAt: true,
      favoriteItem: true,
      receivedAt: true,
      lastMentionedAt: true,
      spaceAccountNote: true,
      path: true,
      isAvailable: true,
      lastViewedAt: true,
      store: false,
      id: observable,
      remoteData: observable,
      isOwnedByMe: computed,
      isFavorited: computed,
      isTrashed: computed,
      data: computed,
      isNoteContentDocumentLoaded: computed,
      remotePermissions: computed,
      grantedCollectionIds: computed,
      grantedSpaceAccountIds: computed,
      grantedEmailAddresses: computed,
      isShared: computed,
      hasPendingShare: computed,
      addToRecents: action,
      toggleFavorite: action,
      delete: action,
      deleteEmptyNote: action,
      moveToTrash: action,
      restoreFromTrash: action,
      saveAsNewNote: action,
      updateContentUsingDiff: action,
      grantAccessViaEmailAddress: action,
      grantAccessViaSpaceAccount: action,
      updateAccessViaEmailAddress: action,
      updateAccessViaSpaceAccount: action,
      updateAccessViaCollection: action,
      revokeAccessViaSpaceAccount: action,
      revokeAccessViaEmailAddress: action,
      isDeleted: observable,
      canAccess: observable,
      canWrite: computed,
      remoteContent: computed,
      modelData: computed,
      modelVersion: computed,
      modelScopes: computed,
      queuedDocumentUpdates: computed,
      permissions: computed,
      primaryOwner: computed,
      authors: computed,
      sharedWithLabels: computed,
      title: computed,
      createdAt: computed,
      modifiedAt: computed,
      updateFromRemote: action,
      deleteFromRemote: action,
      setCanAccess: action,
      uploadImageAssociatedWithNote: action,
      generateCommonEditorContext: action,
    });
  }
  secondaryTitle: string = "";
  sharedBy: ContactObservable | undefined;
  trashedAt: string | null = null;
  favoriteItem?: FavoriteItemObservable | undefined;
  receivedAt: string | null | undefined;
  lastMentionedAt: string | undefined;
  spaceAccountNote: SpaceAccountNoteObservable | undefined;
  path: string = "";
  get isAvailable(): boolean {
    return !this.isTrashed && !this.isDeleted && this.canAccess;
  }

  get lastViewedAt(): string {
    return this.createdAt;
  }

  get canWrite(): boolean {
    return canWrite({
      model: this,
      myPersonalSpaceAccountId: this.store.guestAccount.myPersonalSpaceAccountId,
      permissions: this.permissions,
    });
  }

  updateFromRemote({ data }: { data: SyncUpdateValue<NoteModelData> }) {
    this.remoteData = data;
    this.isDeleted = false;
    this.setCanAccess(true);
  }

  deleteFromRemote() {
    this.isDeleted = true;
  }

  setCanAccess(canAccess: boolean) {
    this.canAccess = canAccess;
  }

  get isOwnedByMe() {
    return false;
  }

  get isFavorited() {
    return false;
  }

  get isTrashed() {
    return this.modelData.trashed_at !== null;
  }

  get remoteContent(): string | null {
    // TODO: This needs to be fixed after we start supporting guest notes again
    return null;
  }

  get data(): SyncUpdateValue<NoteModelData> {
    let output = this.remoteData;
    const optimisticUpdates =
      this.store.sync.actionQueue.optimisticUpdatesByModelId.get(this.id) || [];
    for (const update of optimisticUpdates) {
      if (update.kind === "UPSERTED" || update.kind === "ACL_UPSERTED") {
        const updateValue = update as SyncModelUpsertedSyncUpdate<NoteModelData>;
        output = updateValue.value;
      }
      if (update.kind === "DELETED") {
        this.isDeleted = true;
      }
      if (update.kind === "ACL_REVOKED") {
        this.setCanAccess(false);
      }
    }
    return output;
  }

  get modelData(): NoteModelData {
    return this.data.model_data;
  }

  get modelVersion(): number {
    return this.data.model_version;
  }

  get modelScopes(): SyncModelScope[] {
    return this.data.model_scopes;
  }

  get isNoteContentDocumentLoaded() {
    // TODO: We need to fetch this when we implement guest notes
    return false;
  }

  get queuedDocumentUpdates() {
    return getQueuedDocumentUpdates({
      operationsByModelId: this.store.sync.actionQueue.operationsByModelId,
      id: this.id,
    });
  }

  get remotePermissions(): SyncModelPermissionEntryWithStatus[] {
    return getRemotePermissionsForModel({
      remoteData: this.remoteData,
    });
  }

  get permissions(): SyncModelPermissionEntryWithStatus[] {
    return getPermissionsForNoteSyncModel({
      id: this.id,
      remoteData: this.remoteData,
      store: this.store,
      actionQueue: this.store.sync.actionQueue,
    });
  }

  get grantedCollectionIds(): string[] {
    return getGrantedCollectionIdsForPermissions({ permissions: this.remotePermissions });
  }

  get grantedSpaceAccountIds(): string[] {
    return getGrantedSpaceAccountIdsForPermissions({ permissions: this.remotePermissions });
  }

  get grantedEmailAddresses(): string[] {
    return getGrantedEmailAddressesForPermissions({ permissions: this.remotePermissions });
  }

  get primaryOwner(): IContactModel {
    const spaceAccountId = this.data.model_data.owned_by_space_account_id;
    const spaceAccount =
      this.store.contacts.getContactObservableByContactSpaceAccountId(spaceAccountId);

    return spaceAccount as IContactModel;
  }

  get authors(): IContactModel[] {
    const spaceAccountIds = uniq([
      this.data.model_data.owned_by_space_account_id,
      ...this.data.model_data.modified_by_space_account_ids,
    ]);
    const getContactObservableByContactSpaceAccountId = (spaceAccountId: string) => {
      if (spaceAccountId === this.store.guestAccount.myPersonalSpaceAccountId) {
        return this.store.guestAccount.sharingAccount;
      }
      return this.store.contacts.getContactObservableByContactSpaceAccountId(spaceAccountId);
    };
    const contacts = spaceAccountIds.map(getContactObservableByContactSpaceAccountId);
    return filter(contacts) as IContactModel[];
  }

  get sharedWithLabels(): string[] {
    // TODO: implement
    return [];
  }

  get title(): string {
    return this.modelData.primary_label || UNTITLED_NOTE_TITLE;
  }

  get createdAt(): string {
    return this.modelData.locally_created_at;
  }

  get modifiedAt(): string {
    return this.modelData.locally_modified_at;
  }

  get isShared() {
    return true;
  }

  get hasPendingShare(): boolean {
    for (const permission of this.permissions) {
      if (permission.status === "PENDING") return true;
    }
    return false;
  }

  addToRecents() {}

  toggleFavorite() {}

  delete() {}

  deleteEmptyNote() {}

  moveToTrash() {}

  restoreFromTrash() {}

  saveAsNewNote() {}

  updateContentUsingDiff({
    encodedContentDiff,
    primaryLabel,
    secondaryLabel,
  }: {
    encodedContentDiff: string | null;
    primaryLabel: string;
    secondaryLabel: string;
  }) {
    // TODO: Handle null properly
    new UpdateNoteContentUsingDiffOperation({
      store: this.store,
      payload: {
        id: this.id,
        encoded_content_diff: encodedContentDiff || "",
      },
      primaryLabel,
      secondaryLabel,
    }).execute();
  }

  grantAccessViaEmailAddress({
    targetEmailAddress,
    roleKind,
  }: {
    targetEmailAddress: string;
    roleKind: GrantableSyncScopeRoleKind;
  }) {
    new GrantNoteAclViaEmailAddressOperationGuestMode({
      store: this.store,
      payload: {
        id: this.id,
        email_address: targetEmailAddress,
        role_kind: roleKind,
      },
    }).execute();
  }

  grantAccessViaSpaceAccount() {
    throw new Error("[GuestNoteObservable] grantAccessViaSpaceAccount - NOT SUPPORTED");
  }

  updateAccessViaEmailAddress({
    targetEmailAddress,
    roleKind,
  }: {
    targetEmailAddress: string;
    roleKind: GrantableSyncScopeRoleKind;
  }) {
    new UpdateNoteAclViaEmailAddressOperationGuestMode({
      store: this.store,
      payload: {
        id: this.id,
        email_address: targetEmailAddress,
        role_kind: roleKind,
      },
    });
  }

  updateAccessViaSpaceAccount() {
    throw new Error("[GuestNoteObservable] updateAccessViaSpaceAccount - NOT SUPPORTED");
  }

  updateAccessViaCollection() {
    throw new Error("[GuestNoteObservable] updateAccessViaCollection - NOT SUPPORTED");
  }

  revokeAccessViaSpaceAccount() {
    throw new Error("[GuestNoteObservable] revokeAccessViaSpaceAccount - NOT SUPPORTED");
  }

  revokeAccessViaEmailAddress({ targetEmailAddress }: { targetEmailAddress: string }) {
    new RevokeNoteAclViaEmailAddressOperationGuestMode({
      store: this.store,
      payload: {
        id: this.id,
        email_address: targetEmailAddress,
      },
    });
  }

  public uploadImageAssociatedWithNote({ fileInfo }: { fileInfo: MemCommonEditorFileInfo }) {
    /** Not currently supported in guest mode. */
  }

  public generateCommonEditorContext(): MemCommonEditorContext {
    return {
      files: {},
    };
  }
}
