import { compact, orderBy } from "lodash-es";
import { makeObservable, computed } from "mobx";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { INoteObservable } from "@/store/note";

const MAX_RECENTS_TO_SHOW = 10;

export class AppStoreRecentItemsStore extends AppSubStore {
  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);
    makeObservable(this, {
      sortedRecentCollectionsNotInteractedWithByMe: computed,
      sortedRecentCollectionsInteractedWithByMe: computed,
      sortedRecentCollections: computed,
      sortedRecentNotesInteractedWithByMe: computed,
      sortedRecentItemsInteractedWithByMe: computed,
      sortedRecentItems: computed,
    });
  }

  get sortedRecentCollectionsNotInteractedWithByMe(): CollectionObservable[] {
    const interactedWithCollectionIds = new Set(
      this.sortedRecentCollectionsInteractedWithByMe.map(collection => collection.id)
    );

    const remainingSharedCollections = orderBy(
      this.store.collections.allCollections.filter(
        collection =>
          collection.isShared &&
          !interactedWithCollectionIds.has(collection.id) &&
          !collection.isTrashed &&
          !collection.isDeleted
      ),
      collection => collection.lastAddedToAt || collection.sharedAt,
      "desc"
    );

    return remainingSharedCollections.slice(0, MAX_RECENTS_TO_SHOW);
  }

  get sortedRecentCollectionsInteractedWithByMe(): CollectionObservable[] {
    return compact(
      orderBy(
        this.store.spaceAccountCollections.allSpaceAccountCollections.filter(
          spaceAccountCollection => !spaceAccountCollection.isItemTrashedOrDeleted
        ),
        spaceAccountCollection => spaceAccountCollection.lastInteractedAt, // Dropdowns should account for lastInteractedAt
        "desc"
      )
        .slice(0, MAX_RECENTS_TO_SHOW)
        .map(spaceAccountCollection => spaceAccountCollection.item)
    );
  }

  get sortedRecentCollections(): CollectionObservable[] {
    return [
      ...this.sortedRecentCollectionsInteractedWithByMe,
      ...this.sortedRecentCollectionsNotInteractedWithByMe,
    ].slice(0, MAX_RECENTS_TO_SHOW);
  }

  get sortedRecentNotesInteractedWithByMe(): INoteObservable[] {
    return compact(
      orderBy(
        this.store.spaceAccountNotes.allSpaceAccountNotes.filter(
          spaceAccountNote => !spaceAccountNote.isItemTrashedOrDeleted
        ),
        spaceAccountNote => spaceAccountNote.lastInteractedAt, // Dropdowns should account for lastInteractedAt
        "desc"
      )
        .slice(0, MAX_RECENTS_TO_SHOW)
        .map(spaceAccountNote => spaceAccountNote.item)
    );
  }

  get sortedRecentItemsInteractedWithByMe(): (INoteObservable | CollectionObservable)[] {
    const recentItems = [
      ...this.store.spaceAccountNotes.allSpaceAccountNotes,
      ...this.store.spaceAccountCollections.allSpaceAccountCollections,
    ];
    return compact(
      orderBy(
        recentItems.filter(recentItem => !recentItem.isItemTrashedOrDeleted),
        recentItem => recentItem.lastViewedAt, // CMD-K recents should only account for lastViewedAt
        "desc"
      )
        .slice(0, MAX_RECENTS_TO_SHOW)
        .map(recentItem => recentItem.item)
    );
  }

  get sortedRecentItems(): (INoteObservable | CollectionObservable)[] {
    return [
      ...this.sortedRecentItemsInteractedWithByMe,
      ...this.sortedRecentCollectionsNotInteractedWithByMe,
    ].slice(0, MAX_RECENTS_TO_SHOW);
  }
}
