import { FavoritesList } from "@/components/layout/components/FavoritesList";
import { RecentsList } from "@/components/layout/components/RecentsList";
import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { MdsDropdownContentList, MdsDropdownItemKind } from "@/design-system/components/dropdown";
import { MdsDropdownContent } from "@/design-system/components/dropdown/MdsDropdownContent";
import { MdsIconKind } from "@/design-system/components/icon";
import { mdsSpacings, mdsColors } from "@/design-system/foundations";
import { css } from "@/domains/emotion";
import { useAppStore } from "@/store";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import styled from "@emotion/styled";
import { useMatch } from "react-router";
import { appRoutes } from "@/app/router";

export const SidebarContents = observer(({ onClick }: { onClick?: () => void }) => {
  const { store } = useAppStore();

  const isChatPath = useMatch(appRoutes.chat({ starPattern: false }));
  const isNotesPath = useMatch(appRoutes.notesList({ starPattern: false }));
  const isCollectionsPath = useMatch(appRoutes.collections({ starPattern: false }));

  const libraryShortcuts: MdsDropdownContentList = useMemo(() => {
    return {
      items: [
        {
          id: "library-heading",
          kind: MdsDropdownItemKind.Detail,
          text: "Library",
        },
        {
          id: "notes",
          kind: MdsDropdownItemKind.Button,
          label: "Notes",
          iconKind: MdsIconKind.Notebook,
          className: isNotesPath ? activeContentListItemStyles : undefined,
          onClick: () => {
            onClick?.();
            store.navigation.goToNotes();
          },
        },
        {
          id: "collections",
          kind: MdsDropdownItemKind.Button,
          label: "Collections",
          iconKind: MdsIconKind.Collection,
          className: isCollectionsPath ? activeContentListItemStyles : undefined,
          onClick: () => {
            onClick?.();
            store.navigation.goToCollections();
          },
        },
      ],
    };
  }, [store.navigation, isNotesPath, isCollectionsPath, onClick]);

  return (
    <>
      <SidebarButtonsWrapper>
        <SidebarButton
          label="Create note"
          variant={MdsButtonVariant.Tertiary}
          iconKind={MdsIconKind.ComposeInline}
          innerStyles={{ Label: SidebarButtonLabel }}
          onClick={() => {
            onClick?.();
            store.notes.composeNewNote();
          }}
        />

        <SidebarButton
          label="Chat"
          variant={MdsButtonVariant.TextTertiary}
          className={isChatPath ? activeContentListItemStyles : undefined}
          innerStyles={{ Label: SidebarButtonLabel }}
          onClick={() => {
            onClick?.();
            store.navigation.goToChat();
          }}
          iconKind={MdsIconKind.Message}
        />
      </SidebarButtonsWrapper>
      <ScrollablePortion>
        <MdsDropdownContent contentList={libraryShortcuts} className={transparentGroupStyles} />
        <RecentsList
          className={transparentGroupStyles}
          header="Recents"
          recentsList={store.recentItems.sortedRecentItems}
          selectedItemClassName={activeContentListItemStyles}
          limit={5}
          onClick={onClick}
          animateItems
        />
        <FavoritesList className={transparentGroupStyles} onClick={onClick} animate={false} />
      </ScrollablePortion>
    </>
  );
});

const SidebarButtonsWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: mdsSpacings().xs,
});

const SidebarButton = styled(MdsButton)({
  height: "32px",
  alignItems: "flex-start",
});

const SidebarButtonLabel = css({
  marginLeft: "4px",
});

const ScrollablePortion = styled.div({
  overflowY: "auto",
});
ScrollablePortion.displayName = "ScrollablePortion";

const transparentGroupStyles = css({
  backgroundColor: `transparent`,
  boxShadow: `none`,
  width: `100%`,
  border: 0,
  height: "auto",
});

const activeContentListItemStyles = css({
  backgroundColor: mdsColors().lilac.pale,
});
