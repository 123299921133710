import { Maybe } from "@/domains/common/types";
import { Uuid } from "@/domains/global/identifiers";
import localDb from "@/domains/local-db";
import { forceNetworkCheck, getIsOnline, getWhenOnline } from "@/domains/network/status";
import { logger } from "@/modules/logger";
import { objectModule } from "@/modules/object";
import { toastModule } from "@/modules/toast";
import { AppStore } from "@/store/AppStore";
import { GuestAppStore } from "@/store/GuestAppStore";
import { BaseSyncOperationGeneric } from "@/store/sync/operations/BaseSyncOperationGeneric";
import {
  SyncError,
  SyncErrorDisplayType,
  SyncErrorHandlingType,
} from "@/store/sync/operations/errors/SyncError";
import { SyncErrorModalFields } from "@/store/sync/operations/errors/SyncErrorModalFields";
import { TrashNoteOperation } from "@/store/sync/operations/notes/TrashNoteOperation";
import { UpdateNoteContentUsingDiffOperation } from "@/store/sync/operations/notes/UpdateNoteContentUsingDiffOperation";
import { SyncOperationGeneric } from "@/store/sync/operations/types";
import {
  OptimisticSyncUpdate,
  SyncOperationKind,
  SyncModelKind,
  SyncUpdate,
  SyncModelData,
  QueueProcessingState,
  SyncCustomErrorData,
  GenericSyncOperationKind,
} from "@/store/sync/types";
import { getLoggableOperation } from "@/store/sync/utils";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { DateTime } from "luxon";
import { observable, ObservableMap, makeObservable, action, runInAction, computed } from "mobx";
import pRetry from "p-retry";

const MAXIMUM_RETRY_TIMEOUT = import.meta.env.VITE_MAXIMUM_SYNC_ENGINE_RETRY_TIMEOUT ?? 5 * 1000; // 5 seconds

export abstract class BaseSyncActionQueue<
  Store extends AppStore | GuestAppStore,
> extends AppSubStore<Store> {
  protected getSpaceId: () => string;

  // OPERATIONS
  public processing: SyncOperationGeneric[] = [];
  public pending: SyncOperationGeneric[] = [];
  public operationsByOperationKind: ObservableMap<
    GenericSyncOperationKind,
    SyncOperationGeneric[]
  > = new ObservableMap();
  public operationsByModelId: ObservableMap<Uuid, SyncOperationGeneric[]> = new ObservableMap();

  // OPTIMISTIC UPDATES
  public optimisticUpdates: OptimisticSyncUpdate[] = [];
  public optimisticUpdatesBySyncOperationId: ObservableMap<Uuid, OptimisticSyncUpdate[]> =
    new ObservableMap();
  public optimisticUpdatesByModelId: ObservableMap<Uuid, OptimisticSyncUpdate[]> =
    new ObservableMap();
  public optimisticUpdatesByModelKind: ObservableMap<SyncModelKind, OptimisticSyncUpdate[]> =
    new ObservableMap();

  // LOCAL DB
  abstract saveSyncOperation(syncOperation: SyncOperationGeneric): void;
  abstract saveAcknowledgedSyncOperation(acknowledgedSyncOperation: SyncOperationGeneric): void;
  abstract removeSyncOperation(id: string): void;
  abstract saveOptimisticUpdate(update: OptimisticSyncUpdate): void;
  abstract removeOptimisticUpdate(id: string): void;
  abstract loadAllItems(): Promise<{
    acknowledgedSyncOperations: SyncOperationGeneric[];
    syncOperations: SyncOperationGeneric[];
    optimisticUpdates: OptimisticSyncUpdate[];
  }>;

  // ERROR HANDLING
  abstract get syncErrorModalFields(): Maybe<SyncErrorModalFields>;
  public lastProcessingItemStart?: Date;
  public lastProcessingItemStop?: Date;
  public lastSentOperation: SyncOperationGeneric | undefined;
  public processingError?: SyncError;
  public isFailing = false;

  // OPERATION PROCESSING
  abstract processOperation(operation: SyncOperationGeneric): Promise<Maybe<SyncOperationGeneric>>;
  public processingState: QueueProcessingState = QueueProcessingState.NotReady;

  constructor({
    getSpaceId,
    ...injectedDeps
  }: { getSpaceId: () => string } & AppSubStoreArgs<Store>) {
    super(injectedDeps);
    this.getSpaceId = getSpaceId;
    makeObservable<
      this,
      | "getSpaceId"
      | "isOperationGone"
      | "addToOperationsMap"
      | "addToAllOperationMaps"
      | "removeFromOperationsMap"
      | "removeFromAllOperationMaps"
      | "addToOptimisticUpdatesMap"
      | "addToAllOptimisticUpdatesMaps"
      | "removeFromOptimisticUpdatesMap"
      | "removeFromAllOptimisticUpdatesMaps"
    >(this, {
      getSpaceId: false,

      processing: observable,
      pending: observable,
      optimisticUpdates: observable,

      operationsByOperationKind: observable,
      operationsByModelId: observable,
      optimisticUpdatesBySyncOperationId: observable,
      optimisticUpdatesByModelId: observable,
      optimisticUpdatesByModelKind: observable,

      // OPERATIONS
      addToProcessing: action,
      moveFromProcessingToPending: action,
      removeFromProcessing: action,
      addToPending: action,
      removeFromPending: action,
      addToOperationsMap: action,
      removeFromOperationsMap: action,
      addToAllOperationMaps: action,
      removeFromAllOperationMaps: action,

      // OPTIMISTIC UPDATES
      applyOptimisticUpdate: action,
      removeAllOptimisticUpdatesByModelKind: action,
      removeAllOptimisticUpdatesByModelId: action,
      removeAllOptimisticUpdates: action,
      removeAllOptimisticUpdatesBySyncOperationId: action,
      addToOptimisticUpdatesMap: action,
      removeFromOptimisticUpdatesMap: action,
      addToAllOptimisticUpdatesMaps: action,
      removeFromAllOptimisticUpdatesMaps: action,

      // LOCAL DB OPERATIONS
      loadAllItems: false,
      saveSyncOperation: false,
      saveAcknowledgedSyncOperation: false,
      removeSyncOperation: false,
      saveOptimisticUpdate: false,
      removeOptimisticUpdate: false,

      // PROCESSING + PENDING QUEUES
      push: action,
      process: action,
      start: action,
      confirmSyncUpdatesUntil: action,
      processOperation: false,

      // ERROR HANDLING
      lastSentOperation: observable,
      lastProcessedAt: computed,
      processingError: observable,
      skipAndRevertOperation: action,
      skipAndRevertOperationById: action,
      skipAndRevertRelatedOperations: action,
      skipAndRevertRelatedOperationsById: action,
      skipAndRevertUnsyncedOperationsForModelId: action,
      handleCustomError: action,
      handleSyncError: action,
      isOperationGone: false,
      syncErrorModalFields: false,

      // QUEUE STATUS
      lastProcessingItemStart: observable,
      lastProcessingItemStop: observable,
      isFailing: observable,
      processingState: observable,
      isLoading: computed,
      didFail: action,
      setState: action,
      pause: action,
      resume: action,

      // HYDRATION AND INITIALIZATION
      hydrateFromStorage: action,
      reset: action,
    });
  }

  public push(syncOperation: SyncOperationGeneric) {
    this.addToProcessing(syncOperation);
    if (this.processing.length > 0) this.process();
  }

  public start() {
    logger.debug({ message: "[Queue] Start" });
    this.setState(QueueProcessingState.Ready);
    this.process();
  }

  public async process() {
    if (this.processingState === QueueProcessingState.NotReady) {
      logger.debug({ message: "[Queue] Not ready" });
      return;
    }

    if (this.processingState === QueueProcessingState.Processing) return;
    if (this.processingState === QueueProcessingState.Paused) return;

    logger.debug({ message: "[Queue] Process" });
    this.setState(QueueProcessingState.Processing);

    try {
      while (
        this.processing.length > 0 &&
        (this.processingState as QueueProcessingState) !== QueueProcessingState.Paused
      ) {
        const operation = this.processing[0];
        const deadline = DateTime.now().plus({ seconds: 30 });

        const acknowledgedOperation = await pRetry<SyncOperationGeneric | SyncError | undefined>(
          async () => {
            if (!this.processing.length) return;
            if (this.processingState === QueueProcessingState.Paused) return;
            if (!getIsOnline()) await getWhenOnline();
            runInAction(() => (this.lastProcessingItemStart = new Date()));
            logger.debug({ message: `[Queue] Processing ${operation.id}` });
            return this.processOperation(operation);
          },
          {
            forever: true,
            maxTimeout: MAXIMUM_RETRY_TIMEOUT,
            randomize: true,
            onFailedAttempt: error => {
              logger.error({
                message: "[Queue] Error syncing operation",
                info: {
                  operation: getLoggableOperation(this.processing[0]),
                  error: objectModule.safeErrorAsJson(error),
                },
              });
              forceNetworkCheck();
            },
            shouldRetry: error => {
              if (!(error instanceof SyncError)) {
                this.handleCustomError(operation, { kind: "UNKNOWN" });
                return false;
              }

              if (error.handlingType === SyncErrorHandlingType.RetryWithLimit) {
                return deadline.diffNow().milliseconds > 0;
              }

              if (error.handlingType === SyncErrorHandlingType.RetryForever) {
                // TRANSIENT errors are retried indefinitely but should display a toast eventually.
                // Display toast on the 5th, 15th, 25th, etc error.
                if (
                  error.attemptNumber % 10 === 5 &&
                  error.displayType === SyncErrorDisplayType.Toast
                ) {
                  // Replace a previous toast for the same operation.
                  toastModule.triggerToast({
                    content: error.toastMessage ?? error.message,
                    toastId: error.operationId,
                  });
                }
                return true;
              }

              return false;
            },
          }
        )
          .catch(error => {
            return error;
          })
          .finally(() => {
            runInAction(() => (this.lastProcessingItemStop = new Date()));
          });

        // If this returns undefined, we keep the operation in the processing queue and retry indefinitely
        if (acknowledgedOperation === undefined) {
          continue;
        }

        // If there is a sync error, we handle it a bit differently
        if (acknowledgedOperation instanceof SyncError) {
          this.handleSyncError(operation, acknowledgedOperation);
          continue;
        }

        // If the action is acknowledged, we need to move it to the pending queue and move on to the next operation
        if (acknowledgedOperation instanceof BaseSyncOperationGeneric) {
          logger.debug({
            message: "[SYNC][SyncActionQueue] acknowledgedOperation",
            info: { acknowledgedOperation: getLoggableOperation(acknowledgedOperation) },
          });

          runInAction(() => {
            this.moveFromProcessingToPending(acknowledgedOperation);
            this.lastSentOperation = acknowledgedOperation;
            this.isFailing = false;
          });

          if (
            this.store.sync.latestSpaceAccountSequenceId &&
            acknowledgedOperation.latestSpaceAccountSequenceId &&
            acknowledgedOperation.latestSpaceAccountSequenceId <=
              this.store.sync.latestSpaceAccountSequenceId
          ) {
            this.confirmSyncUpdatesUntil(this.store.sync.latestSpaceAccountSequenceId);
          }
        }
      }
    } finally {
      if ((this.processingState as QueueProcessingState) === QueueProcessingState.Processing) {
        logger.debug({ message: "[Queue] Done" });
        this.setState(QueueProcessingState.Ready);
      }
    }
  }

  public confirmSyncUpdatesUntil(latestSequenceId: number) {
    // Find all of the acknowledged operations on pending to remove
    // Remove all pending operations + optimistic updates
    logger.debug({
      message: "[SYNC][SyncActionQueue] confirmSyncUpdatesUntil ",
      info: { latestSequenceId },
    });
    const confirmedSyncOperations = this.pending.filter(operation => {
      if (!operation.latestSpaceAccountSequenceId) return true;
      if (operation.latestSpaceAccountSequenceId <= latestSequenceId) return true;
      return false;
    });
    for (const syncOperation of confirmedSyncOperations) {
      this.removeFromPending(syncOperation);
      this.removeAllOptimisticUpdatesBySyncOperationId(syncOperation.id);
    }
  }

  // ERROR HANDLING
  private isOperationGone(operation: SyncOperationGeneric) {
    return !this.processing.find(e => e.id === operation.id);
  }

  // Without resume to avoid UI flickering these are suitable for toasts only.
  skipAndRevertOperationById = (id: string): boolean => {
    const operation = this.processing.find(e => e.id === id);
    if (!operation) return false;
    return this.skipAndRevertOperation(operation);
  };

  skipAndRevertOperation = (operation: SyncOperationGeneric): boolean => {
    if (this.isOperationGone(operation)) return false;
    logger.debug({
      message: "[Queue] Reverting...",
      info: { operation: getLoggableOperation(operation) },
    });
    runInAction(() => {
      this.removeFromProcessing(operation);
      this.removeAllOptimisticUpdatesBySyncOperationId(operation.id);
    });
    return true;
  };

  skipAndRevertRelatedOperations = (operation: SyncOperationGeneric): boolean => {
    if (operation instanceof TrashNoteOperation) {
      const operations = this.operationsByModelId.get((operation as TrashNoteOperation).payload.id);
      const filtered = operations?.filter(
        e => ["TRASH_NOTE", "DELETE_NOTE"].includes(e.operationKind) && !e.acknowledged
      );
      filtered?.forEach(this.skipAndRevertOperation);
    }
    if (operation instanceof UpdateNoteContentUsingDiffOperation) {
      const operations = this.operationsByModelId.get(
        (operation as UpdateNoteContentUsingDiffOperation).payload.id
      );
      const filtered = operations?.filter(
        e =>
          ["CREATE_NOTE", "UPDATE_NOTE_CONTENT_USING_DIFF", "TRASH_NOTE", "DELETE_NOTE"].includes(
            e.operationKind
          ) && !e.acknowledged
      );
      filtered?.forEach(this.skipAndRevertOperation);
    }
    return this.skipAndRevertOperation(operation);
  };

  skipAndRevertUnsyncedOperationsForModelId = (id: string) => {
    const operations = this.operationsByModelId.get(id)?.filter(e => !e.acknowledged);
    if (operations?.length) {
      // May drop the first operation too even if it's running right now.
      const revertibleOperations = operations;
      revertibleOperations.forEach(this.skipAndRevertOperation);
    }
    this.resume();
  };

  skipAndRevertRelatedOperationsById = (id: string) => {
    const operation = this.processing.find(e => e.id === id);
    if (operation) {
      this.skipAndRevertRelatedOperations(operation);
    }
    this.resume();
  };

  handleCustomError(operation: SyncOperationGeneric, errorData: SyncCustomErrorData): void {
    if (errorData.kind === "NOT_FOUND") return operation.handleNotFoundError(errorData);
    if (errorData.kind === "INVALID") return operation.handleInvalidError(errorData);
    if (errorData.kind === "PERMISSION_DENIED")
      return operation.handlePermissionDeniedError(errorData);
    if (errorData.kind === "TRANSIENT") return operation.handleTransientError(errorData);
    if (errorData.kind === "UNKNOWN") return operation.handleUnknownError(errorData);
  }

  // Handle SyncError after all retries are exhausted
  handleSyncError(operation: SyncOperationGeneric, error: SyncError): void {
    logger.debug({
      message: "[Queue] handleSyncError",
      info: {
        operation: getLoggableOperation(operation),
        error: objectModule.safeErrorAsJson(error),
      },
    });

    switch (error.handlingType) {
      case SyncErrorHandlingType.Revert: {
        this.skipAndRevertOperation(operation);
        if (error.displayType === SyncErrorDisplayType.Toast) {
          toastModule.triggerToast({
            content: error.toastMessage ?? error.message,
            toastId: error.operationId,
          });
        } else if (error.displayType === SyncErrorDisplayType.Modal) {
          this.didFail(error);
        }
        break;
      }
      case SyncErrorHandlingType.RetryWithLimit:
      case SyncErrorHandlingType.RetryForever: {
        error.retryEndActionHandler?.();
        if (error.displayType === SyncErrorDisplayType.Toast) {
          toastModule.triggerToast({
            content: error.toastMessage ?? error.message,
            toastId: error.operationId,
          });
        } else if (error.displayType === SyncErrorDisplayType.Modal) {
          this.didFail(error);
        } else if (error.displayType === SyncErrorDisplayType.None) {
          this.skipAndRevertOperation(operation);
        }
        break;
      }
      case SyncErrorHandlingType.Fail: {
        this.didFail(error);
        break;
      }
    }
  }

  didFail(syncError?: SyncError) {
    this.isFailing = true;
    if (syncError) this.processingError = syncError;
    this.pause();
  }

  // QUEUE STATUS
  setState = (state: QueueProcessingState) => {
    this.processingState = state;
  };

  get isLoading() {
    return this.processingState === QueueProcessingState.NotReady;
  }

  get lastProcessedAt(): Maybe<DateTime> {
    return this.lastProcessingItemStop
      ? DateTime.fromJSDate(this.lastProcessingItemStop)
      : undefined;
  }

  public pause() {
    logger.debug({ message: "[SYNC][SyncActionQueue] Pausing..." });
    this.setState(QueueProcessingState.Paused);
  }

  public resume = () => {
    logger.debug({
      message: "[SYNC][SyncActionQueue] Resuming...",
      info: objectModule.safeAsJson({
        state: this.processingState,
        processingError: this.processingError,
        isFailing: this.isFailing,
        lastProcessingItemStart: this.lastProcessingItemStart?.toISOString(),
        lastProcessingItemStop: this.lastProcessingItemStop?.toISOString(),
      }),
    });

    if (this.processingState !== QueueProcessingState.Paused) return;

    this.setState(QueueProcessingState.Ready);
    this.processingError = undefined;
    this.isFailing = false;
    this.process();
  };

  async reset() {
    logger.debug({ message: "[Queue] Reset" });
    await localDb.queue.clear();
    runInAction(() => {
      this.pending = [];
      this.processing = [];
      this.optimisticUpdates = [];
      this.operationsByModelId.clear();
      this.operationsByOperationKind.clear();
      this.optimisticUpdatesByModelId.clear();
      this.optimisticUpdatesByModelKind.clear();
      this.optimisticUpdatesBySyncOperationId.clear();
      this.lastSentOperation = undefined;
      this.isFailing = false;
      this.processingError = undefined;
      this.lastProcessingItemStart = undefined;
      this.lastProcessingItemStop = undefined;
      this.setState(QueueProcessingState.NotReady);
    });
    this.resume();
  }

  // OPERATIONS
  public addToProcessing(
    operation: SyncOperationGeneric,
    {
      isHydrating = false,
      addToAllOperationMaps = true,
    }: { isHydrating?: boolean; addToAllOperationMaps?: boolean } = {}
  ) {
    this.processing.push(operation);
    if (addToAllOperationMaps) this.addToAllOperationMaps(operation);
    if (!isHydrating) this.saveSyncOperation(operation);
  }

  public removeFromProcessing(
    operation: SyncOperationGeneric,
    { removeFromAllOperationMaps = true }: { removeFromAllOperationMaps?: boolean } = {}
  ) {
    this.processing = this.processing.filter(e => e.id !== operation.id);
    if (removeFromAllOperationMaps) this.removeFromAllOperationMaps(operation);
    this.removeSyncOperation(operation.id);
  }

  public moveFromProcessingToPending(operation: SyncOperationGeneric) {
    this.removeFromProcessing(operation, { removeFromAllOperationMaps: false });
    this.addToPending(operation, { addToAllOperationMaps: false });
  }

  public addToPending(
    operation: SyncOperationGeneric,
    {
      isHydrating = false,
      addToAllOperationMaps = true,
    }: { isHydrating?: boolean; addToAllOperationMaps?: boolean } = {}
  ) {
    this.pending.push(operation);
    if (addToAllOperationMaps) this.addToAllOperationMaps(operation);
    if (!isHydrating) this.saveAcknowledgedSyncOperation(operation);
  }

  public removeFromPending(
    operation: SyncOperationGeneric,
    { removeFromAllOperationMaps = true }: { removeFromAllOperationMaps?: boolean } = {}
  ) {
    this.pending = this.pending.filter(e => e.id !== operation.id);
    if (removeFromAllOperationMaps) this.removeFromAllOperationMaps(operation);
    this.removeSyncOperation(operation.id);
  }

  private addToAllOperationMaps(operation: SyncOperationGeneric) {
    this.addToOperationsMap(this.operationsByOperationKind, operation.operationKind, operation);
    if ("id" in operation.payload) {
      this.addToOperationsMap(this.operationsByModelId, operation.payload.id, operation);
    }
    if ("collection_id" in operation.payload) {
      this.addToOperationsMap(this.operationsByModelId, operation.payload.collection_id, operation);
    }
    if ("note_id" in operation.payload) {
      this.addToOperationsMap(this.operationsByModelId, operation.payload.note_id, operation);
    }
  }

  private removeFromAllOperationMaps(operation: SyncOperationGeneric) {
    this.removeFromOperationsMap(
      this.operationsByOperationKind,
      operation.operationKind,
      operation
    );
    if ("id" in operation.payload) {
      this.removeFromOperationsMap(this.operationsByModelId, operation.payload.id, operation);
    }
    if ("collection_id" in operation.payload) {
      this.removeFromOperationsMap(
        this.operationsByModelId,
        operation.payload.collection_id,
        operation
      );
    }
    if ("note_id" in operation.payload) {
      this.removeFromOperationsMap(this.operationsByModelId, operation.payload.note_id, operation);
    }
  }

  private addToOperationsMap(
    map: ObservableMap<Uuid, SyncOperationGeneric[]>,
    key: Uuid,
    operation: SyncOperationGeneric
  ) {
    const existingOperations = map.get(key) || [];
    map.set(key, [...existingOperations, operation]);
  }

  private removeFromOperationsMap(
    map: ObservableMap<Uuid, SyncOperationGeneric[]>,
    key: Uuid,
    operation: SyncOperationGeneric
  ) {
    const existingOperations = map.get(key) || [];
    const filteredOperations = existingOperations.filter(e => e.id !== operation.id);
    map.set(key, filteredOperations);
  }

  // OPTIMISTIC UPDATES
  public applyOptimisticUpdate(
    syncOperationId: Uuid,
    update: SyncUpdate<SyncModelData>,
    { isHydrating = false }: { isHydrating?: boolean } = {}
  ) {
    const optimisticSyncUpdate: OptimisticSyncUpdate = { ...update, syncOperationId };
    logger.debug({
      message: "[SYNC][SyncActionQueue] applyOptimisticUpdate",
      info: { optimisticSyncUpdate: objectModule.safeAsJson(optimisticSyncUpdate) },
    });
    this.optimisticUpdates.push(optimisticSyncUpdate);
    this.addToAllOptimisticUpdatesMaps(optimisticSyncUpdate);
    if (!isHydrating) this.saveOptimisticUpdate(optimisticSyncUpdate);
  }

  public removeAllOptimisticUpdates(optimisticUpdates: OptimisticSyncUpdate[]) {
    const removedSyncIdSet = new Set<string>();
    for (const optimisticUpdate of optimisticUpdates) {
      this.removeFromAllOptimisticUpdatesMaps(optimisticUpdate);
      removedSyncIdSet.add(optimisticUpdate.sync_id);
      this.removeOptimisticUpdate(optimisticUpdate.sync_id);
    }
    this.optimisticUpdates = this.optimisticUpdates.filter(e => !removedSyncIdSet.has(e.sync_id));
  }

  public removeAllOptimisticUpdatesBySyncOperationId(syncOperationId: string) {
    logger.debug({
      message: "[SYNC][SyncActionQueue] removeAllOptimisticUpdatesBySyncOperationId",
      info: { syncOperationId },
    });
    const optimisticUpdates = this.optimisticUpdatesBySyncOperationId.get(syncOperationId) || [];
    this.removeAllOptimisticUpdates(optimisticUpdates);
  }

  public removeAllOptimisticUpdatesByModelKind(modelKind: SyncModelKind) {
    logger.debug({
      message: "[SYNC][SyncActionQueue] removeAllOptimisticUpdatesByModelKind",
      info: { modelKind },
    });
    const optimisticUpdates = this.optimisticUpdatesByModelKind.get(modelKind) || [];
    this.removeAllOptimisticUpdates(optimisticUpdates);
  }

  public removeAllOptimisticUpdatesByModelId(modelId: Uuid) {
    logger.debug({
      message: "[SYNC][SyncActionQueue] removeAllOptimisticUpdatesByModelId",
      info: { modelId },
    });
    const optimisticUpdates = this.optimisticUpdatesByModelId.get(modelId) || [];
    this.removeAllOptimisticUpdates(optimisticUpdates);
  }

  private addToAllOptimisticUpdatesMaps(optimisticUpdate: OptimisticSyncUpdate) {
    this.addToOptimisticUpdatesMap(
      this.optimisticUpdatesBySyncOperationId,
      optimisticUpdate.syncOperationId,
      optimisticUpdate
    );
    this.addToOptimisticUpdatesMap(
      this.optimisticUpdatesByModelId,
      optimisticUpdate.value.model_id,
      optimisticUpdate
    );
    this.addToOptimisticUpdatesMap(
      this.optimisticUpdatesByModelKind,
      optimisticUpdate.value.model_kind,
      optimisticUpdate
    );
  }

  private removeFromAllOptimisticUpdatesMaps(optimisticUpdate: OptimisticSyncUpdate) {
    this.removeFromOptimisticUpdatesMap(
      this.optimisticUpdatesBySyncOperationId,
      optimisticUpdate.syncOperationId,
      optimisticUpdate.syncOperationId
    );
    this.removeFromOptimisticUpdatesMap(
      this.optimisticUpdatesByModelId,
      optimisticUpdate.value.model_id,
      optimisticUpdate.syncOperationId
    );
    this.removeFromOptimisticUpdatesMap(
      this.optimisticUpdatesByModelKind,
      optimisticUpdate.value.model_kind,
      optimisticUpdate.syncOperationId
    );
  }

  private addToOptimisticUpdatesMap(
    map: ObservableMap<Uuid, OptimisticSyncUpdate[]>,
    key: Uuid,
    update: OptimisticSyncUpdate
  ) {
    const existingUpdates = map.get(key) || [];
    map.set(key, [...existingUpdates, update]);
  }

  private removeFromOptimisticUpdatesMap(
    map: ObservableMap<Uuid, OptimisticSyncUpdate[]>,
    key: Uuid,
    syncOperationId: Uuid
  ) {
    const existingUpdates = map.get(key) || [];
    const filteredUpdates = existingUpdates.filter(e => e.syncOperationId !== syncOperationId);
    map.set(key, filteredUpdates);
  }

  // HYDRATION AND INITIALIZATION
  public async hydrateFromStorage() {
    const { optimisticUpdates, acknowledgedSyncOperations, syncOperations } =
      await this.loadAllItems();
    runInAction(() => {
      for (const operation of syncOperations) {
        this.addToProcessing(operation, { isHydrating: true });
      }
      for (const operation of acknowledgedSyncOperations) {
        this.addToPending(operation, { isHydrating: true });
        this.lastSentOperation = operation;
      }
      for (const optimisticUpdate of optimisticUpdates) {
        this.applyOptimisticUpdate(optimisticUpdate.syncOperationId, optimisticUpdate, {
          isHydrating: true,
        });
      }
    });
  }
}
