import { MdsButtonVariant } from "@/design-system/components/button";
import { MdsButton } from "@/design-system/components/button/MdsButton";
import { MdsSpacer } from "@/design-system/components/spacer";
import { MdsText } from "@/design-system/components/text";
import { MdsTextStylingMode } from "@/design-system/components/text/types";
import { UNTITLED_NOTE_TITLE } from "@/domains/untitled/untitled";
import { uuidModule } from "@/modules/uuid";
import { AppStore, GuestAppStore } from "@/store";
import { NoteModelData, NoteUpsertedSyncUpdateValue } from "@/store/note/types";
import {
  BaseSyncOperationGuestMode,
  BaseSyncOperationGuestModeParams,
} from "@/store/sync/operations/BaseSyncOperationGuestMode";
import { AbstractSyncErrorModalFieldsGenerator } from "@/store/sync/operations/errors/SyncErrorModalFields";
import {
  GuestModeSupportedSyncOperationKind,
  IUpdateNoteContentUsingDiffOperation,
  IUpdateNoteContentUsingDiffOperationGuestMode,
} from "@/store/sync/operations/types";
import { SyncCustomErrorData, SyncModelData, SyncUpdate } from "@/store/sync/types";
import { uniq } from "lodash-es";
import { runInAction } from "mobx";

export class UpdateNoteContentUsingDiffOperation extends BaseSyncOperationGuestMode<
  IUpdateNoteContentUsingDiffOperation,
  IUpdateNoteContentUsingDiffOperationGuestMode
> {
  protected primaryLabel: string;
  protected secondaryLabel: string;

  get operationKind(): GuestModeSupportedSyncOperationKind {
    return "UPDATE_NOTE_CONTENT_USING_DIFF";
  }

  constructor({
    primaryLabel,
    secondaryLabel,
    ...params
  }: {
    primaryLabel: string;
    secondaryLabel: string;
  } & BaseSyncOperationGuestModeParams<IUpdateNoteContentUsingDiffOperation>) {
    super(params);
    this.primaryLabel = primaryLabel;
    this.secondaryLabel = secondaryLabel;
  }

  generateOptimisticUpdates(): SyncUpdate<SyncModelData>[] {
    // Apply optimistic updates regardless of whether or not there is a contentDiff
    let note;
    if (this.store instanceof GuestAppStore) {
      note = this.store.note.getNoteObservableById({ noteId: this.payload.id });
    } else {
      note = this.store.notes.get(this.payload.id);
    }
    if (!note) return [];

    const value: NoteUpsertedSyncUpdateValue = {
      model_id: this.payload.id,
      model_kind: "NOTE",
      model_version: note.modelVersion,
      model_data: {
        ...note.modelData,
        primary_label: this.primaryLabel,
        secondary_label: this.secondaryLabel,
        locally_modified_at: this.committedAt,
        modified_by_space_account_ids: uniq([
          ...note.modelData.modified_by_space_account_ids,
          this.store instanceof GuestAppStore
            ? this.store.guestAccount.myPersonalSpaceAccountId
            : this.store.spaceAccounts.myPersonalSpaceAccountId,
        ]),
      },
      model_scopes: note.modelScopes,
    };
    const syncUpdate: SyncUpdate<NoteModelData> = {
      sync_id: uuidModule.generate(),
      committed_at: this.committedAt,
      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value,
    };
    return [syncUpdate];
  }

  execute(): void {
    // This needs to be handled differently because of the potential empty contentDiff.
    // We don't want to process the update if there's no contentDiff, but we still need to add it to pending so we can remove the optimistic updates later
    if (!this.payload.encoded_content_diff) {
      this.acknowledge(0); // Operation + optimistic updates are removed from queue the next time updates are received
      this.store.sync.actionQueue.addToPending(this);
    } else {
      const syncOperation = this.generateSyncOperation() || this.generateSyncOperationGuestMode();
      if (!syncOperation) return;
      this.store.sync.actionQueue.push(this);
    }
    const optimisticUpdates = this.generateOptimisticUpdates();
    for (const optimisticUpdate of optimisticUpdates) {
      this.store.sync.actionQueue.applyOptimisticUpdate(this.id, optimisticUpdate);
    }
  }

  handleInvalidError(_errorData: SyncCustomErrorData) {
    this.triggerModal(
      this.getSyncErrorModalFieldsGenerator("INVALID", {
        requestAccessAndSaveEditsInNewNoteButtonsEnabled: true,
      })
    );
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData) {
    this.triggerModal(
      this.getSyncErrorModalFieldsGenerator("PERMISSION_DENIED", {
        requestAccessAndSaveEditsInNewNoteButtonsEnabled: true,
      })
    );
  }

  handleUnknownError(_errorData: SyncCustomErrorData) {
    this.triggerModal(
      this.getSyncErrorModalFieldsGenerator("UNKNOWN", {
        requestAccessAndSaveEditsInNewNoteButtonsEnabled: true,
      })
    );
  }

  get syncErrorModalFieldsGenerator() {
    return this.getSyncErrorModalFieldsGenerator("UNKNOWN");
  }

  getSyncErrorModalFieldsGenerator(
    kind: SyncCustomErrorData["kind"],
    opts: UpdateNoteContentUsingDiffSyncErrorModalFieldsGeneratorOps = {
      requestAccessAndSaveEditsInNewNoteButtonsEnabled: false,
    }
  ) {
    return (store: AppStore) =>
      new UpdateNoteContentUsingDiffSyncErrorModalFieldsGenerator(
        store,
        this.id,
        this.payload.id,
        kind,
        opts
      );
  }
}

interface UpdateNoteContentUsingDiffSyncErrorModalFieldsGeneratorOps {
  requestAccessAndSaveEditsInNewNoteButtonsEnabled: boolean;
  onAfterClose?: () => void;
}

class UpdateNoteContentUsingDiffSyncErrorModalFieldsGenerator extends AbstractSyncErrorModalFieldsGenerator {
  protected id: string;
  protected noteId: string;
  protected kind: SyncCustomErrorData["kind"];
  protected requestAccessAndSaveEditsInNewNoteButtonsEnabled: boolean;
  protected onAfterClose?: () => void;

  constructor(
    store: AppStore,
    id: string,
    noteId: string,
    kind: SyncCustomErrorData["kind"],
    opts: UpdateNoteContentUsingDiffSyncErrorModalFieldsGeneratorOps
  ) {
    super(store);

    this.id = id;
    this.noteId = noteId;
    this.kind = kind;
    this.requestAccessAndSaveEditsInNewNoteButtonsEnabled =
      opts.requestAccessAndSaveEditsInNewNoteButtonsEnabled;
    this.onAfterClose = opts.onAfterClose;
  }

  reloadEditors() {
    // Any open editor or note viewer will be notified to reload the note without reverted operations.
    this.store.publicAppStore.interface.notifyReloadRequired(this.noteId);
    this.onAfterClose?.();
  }

  requestEditAccess = () => {
    // TODO: https://linear.app/mem-labs/issue/MEM-7702/update-note-contents-permission-denied-request-edit-access
  };

  discardEdits = () => {
    runInAction(() => {
      this.store.sync.actionQueue.skipAndRevertRelatedOperationsById(this.id);
      this.reloadEditors();
      this.store.sync.actionQueue.resume();
    });
  };

  saveChangesAsNewNote = () => {
    // TODO: https://linear.app/mem-labs/issue/MEM-7703/update-note-contents-permission-denied-save-edits-as-new-note
    const note = this.store.notes.get(this.noteId);
    if (!note) return;
    note.saveAsNewNote();
    this.discardEdits();
  };

  tryAgain = () => {
    this.store.sync.actionQueue.resume();
  };

  get title() {
    const note = this.store.notes.get(this.noteId);
    const title = note?.title || UNTITLED_NOTE_TITLE;
    return `Cannot save your changes to “${title}”`;
  }

  get message() {
    switch (this.kind) {
      case "INVALID":
      case "UNKNOWN": {
        return `The edits will be discarded`;
      }
      case "PERMISSION_DENIED": {
        return (
          <>
            You no longer have edit access to this note and your changes could not be saved. Contact
            the note owner, or{" "}
            <MdsText
              stylingMode={MdsTextStylingMode.InheritStyles}
              onClick={this.requestEditAccess}
            >
              request edit access
            </MdsText>
            , then try again.
          </>
        );
      }
    }
  }

  get resetActionLabel() {
    return this.requestAccessAndSaveEditsInNewNoteButtonsEnabled ? null : "Got it";
  }

  get extraActionButtons() {
    if (!this.requestAccessAndSaveEditsInNewNoteButtonsEnabled) return;

    return (
      <>
        <MdsButton
          label="Discard edits"
          variant={MdsButtonVariant.Outlined}
          onClick={this.discardEdits}
        />
        <MdsSpacer />
        <MdsButton
          label="Save changes as new note"
          variant={MdsButtonVariant.Outlined}
          onClick={this.saveChangesAsNewNote}
        />
        <MdsButton
          label="Try again"
          variant={MdsButtonVariant.FilledDark}
          onClick={this.tryAgain}
        />
      </>
    );
  }

  modalActionHandler = async () => {
    this.store.sync.actionQueue.skipAndRevertRelatedOperationsById(this.id);
    this.reloadEditors();
  };
}
