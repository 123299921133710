import { Uuid } from "@/domains/global/identifiers";
import { PusherDomainPrefix, PusherServicePrefix } from "@/domains/pusher/constants";
import { objectModule } from "@/modules/object";
import { validationModule } from "@/modules/validation";
import { SyncOperationGeneric } from "@/store/sync/operations/types";
import { JsonObject } from "type-fest";

export function generateSyncActionSpaceScopedPusherChannelKey({ spaceId }: { spaceId: Uuid }) {
  const uppercaseSpaceId = spaceId.toUpperCase();
  return `${PusherServicePrefix.BACKEND_API}@${PusherDomainPrefix.SPACE_SCOPED_SYNC_UPDATE}@${uppercaseSpaceId}`;
}

export function generateSyncActionCollectionScopedPusherChannelKey({
  collectionId,
}: {
  collectionId: Uuid;
}) {
  const uppercaseCollectionId = collectionId.toUpperCase();
  return `${PusherServicePrefix.BACKEND_API}@${PusherDomainPrefix.COLLECTION_SCOPED_SYNC_UPDATE}@${uppercaseCollectionId}`;
}

export function generateSyncActionSpaceAccountScopedPusherChannelKey({
  spaceAccountId,
}: {
  spaceAccountId: Uuid;
}) {
  const uppercaseSpaceAccountId = spaceAccountId.toUpperCase();
  return `${PusherServicePrefix.BACKEND_API}@${PusherDomainPrefix.SPACE_ACCOUNT_SCOPED_SYNC_UPDATE}@${uppercaseSpaceAccountId}`;
}

export function generateSyncActionChatConversationScopedPusherChannelKey({
  chatConversationId,
}: {
  chatConversationId: Uuid;
}) {
  const uppercaseChatConversationId = chatConversationId.toUpperCase();
  return `${PusherServicePrefix.BACKEND_API}@${PusherDomainPrefix.CHAT_CONVERSATION_SCOPED_SYNC_UPDATE}@${uppercaseChatConversationId}`;
}

export function generateChatConversationLiveSyncUpdatePusherChannelKey({
  chatConversationId,
}: {
  chatConversationId: string;
}) {
  const uppercaseChatConversationId = chatConversationId.toUpperCase();
  return `${PusherServicePrefix.AUTHENTICATED_BACKEND_API}@${PusherDomainPrefix.CHAT_CONVERSATION_LIVE_SYNC_UPDATE}@${uppercaseChatConversationId}`;
}

export function standardizeSyncId(syncId: string | undefined | null) {
  if (!syncId) return undefined;
  const [prefix, uuid] = syncId.split("::");
  if (prefix !== "SYNC") throw new Error("Invalid sync id");
  if (!validationModule.isUuid(uuid)) throw new Error("Invalid sync id");
  return `${prefix}::${uuid.toUpperCase()}`;
}

export function getLoggableOperation(operation?: SyncOperationGeneric) {
  const { ...o } = { ...operation } as unknown as Record<string, unknown>;
  delete o.store;
  o.operationKind = operation?.operationKind || "<missing>";
  return objectModule.safeAsJson(o);
}

export function formatSyncOperation(syncOperation?: SyncOperationGeneric): JsonObject | null {
  if (!syncOperation) return null;

  return {
    operation_id: `${syncOperation.id}`,
    operation_kind: `${syncOperation.operationKind}`,
    payload: syncOperation.payload as JsonObject,
  };
}
export function saveJsonToFile(obj: object, filename: string) {
  const blob = new Blob([JSON.stringify(obj, null, 2)], {
    type: "application/json",
  });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `${filename}.json`;
  a.click();
  URL.revokeObjectURL(url);
}
