import { Uuid } from "@/domains/global/identifiers";
import { NoteObservable } from "@/store/note/NoteObservable";
import { BaseSyncModel } from "@/store/sync/BaseSyncModel";
import { SyncUpdateValue } from "@/store/sync/types";
import { SpaceAccountTopicItemObservable } from "@/store/topics/SpaceAccountTopicItemObservable";
import { SpaceAccountTopicModelData } from "@/store/topics/types";
import { WithAppStore } from "@/store/types";
import { computed, makeObservable } from "mobx";

export class SpaceAccountTopicObservable extends BaseSyncModel<SpaceAccountTopicModelData> {
  constructor({
    id,
    data,
    store,
  }: { id: Uuid; data: SyncUpdateValue<SpaceAccountTopicModelData> } & WithAppStore) {
    super({ id, data, store });
    makeObservable(this, {
      allTopicItems: true,
      title: computed,
      description: computed,
      allItems: computed,
    });
  }

  public get title(): string {
    return this.modelData.title;
  }

  public get description(): string {
    return this.modelData.description;
  }

  public get allTopicItems(): SpaceAccountTopicItemObservable[] {
    return this.store.spaceAccountTopicItems.topicsToSpaceAccountTopicItems.get(this.id) || [];
  }

  public get allItems(): NoteObservable[] {
    const topicItems =
      this.store.spaceAccountTopicItems.topicsToSpaceAccountTopicItems.get(this.id) || [];
    return topicItems.map(item => item.item).filter(item => !!item);
  }
}
