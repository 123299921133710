import { Uuid } from "@/domains/global/identifiers";
import { BaseSyncModel } from "@/store/sync/BaseSyncModel";
import { SyncUpdateValue } from "@/store/sync/types";
import { WithAppStore } from "@/store/types";
import { computed, makeObservable } from "mobx";
import { SpaceAccountFeatureFlagsModelData } from "@/store/feature-flags/types";

export class SpaceAccountFeatureFlagsObservable extends BaseSyncModel<SpaceAccountFeatureFlagsModelData> {
  constructor({
    id,
    data,
    store,
  }: { id: Uuid; data: SyncUpdateValue<SpaceAccountFeatureFlagsModelData> } & WithAppStore) {
    super({ id, data, store });

    makeObservable(this, {
      ownedBySpaceAccountId: computed,
      flags: computed,
    });
  }

  get ownedBySpaceAccountId() {
    return this.modelData.owned_by_space_account_id;
  }

  get flags() {
    return this.modelData.flags;
  }
}
