import { addNotesToCollection } from "@/actions/addNotesToCollection";
import { logger } from "@/modules/logger";
import { toastModule } from "@/modules/toast";
import { uuidModule } from "@/modules/uuid";
import { AppStore } from "@/store";
import { type INoteObservable } from "@/store/note";

export const createCollection = async ({
  title,
  description,
  store,
  notes,
}: {
  title: string;
  description?: string;
  notes?: INoteObservable[];
  store: AppStore;
}) => {
  const collectionId = uuidModule.generate();

  // create the collection
  store.collections.createCollection({
    collectionId,
    title,
    description,
  });
  const newCollection = store.collections.getCollectionObservableById({ collectionId });

  // sanity check and ensure newCollection is not undefined
  if (!newCollection) {
    logger.error({
      message: "[createCollection]: Failed to create collection.",
      info: {
        collectionId,
        title,
      },
    });

    return;
  }

  // make sure the new collection shows up in Recents
  setTimeout(() => {
    newCollection.addToRecents();
  }, 0);

  // optionally attach notes
  if (notes) {
    await addNotesToCollection({
      notes,
      collection: newCollection,
      store,
    });
  } else {
    toastModule.triggerToast({
      content: (
        <div>
          <a onClick={() => store.navigation.goToCollection({ collectionId: newCollection.id })}>
            {newCollection.title}
          </a>{" "}
          created
        </div>
      ),
    });
  }
};
