import { observer } from "mobx-react-lite";
import { RelatedCard } from "@/store/routing/types";
import styled from "@emotion/styled";
import { MdsItemListRowRenderer } from "@/design-system/components/item-list";
import { useAppStore } from "@/store/utils/hooks";
import { CopilotVotingButtons } from "@/components/copilot/TopicItemVotingButtons";
import { MdsItemListItemRowData } from "@/design-system/components/item-list/types";

const MAX_ITEMS_TO_SHOW = 3;

interface Props {
  showContents: boolean;
  card: RelatedCard;
}

export const CollapsedRelatedCard = observer<Props>(function Card({ card, showContents }) {
  const { store, pageStore } = useAppStore();
  const numItemsMore = card.items.length - MAX_ITEMS_TO_SHOW;
  const rows = card.items.slice(0, MAX_ITEMS_TO_SHOW);

  const TopicItemRows = ({
    rows,
    store,
  }: {
    rows: MdsItemListItemRowData[];
    store: typeof pageStore.copilotView;
  }) => {
    return (
      <div>
        {rows.map((row, index) => (
          <div
            key={index}
            onMouseEnter={() => store.setHighlightedRow(row.payload.id, card.id)}
            onMouseLeave={() => store.setHighlightedRow(null, null)}
          >
            <MdsItemListRowRenderer index={index} data={row as MdsItemListItemRowData} />
          </div>
        ))}
      </div>
    );
  };

  // Make the buttons always visible (so CopilotVotingButtons does not worry about whether it is highlighted),
  // but only show their enclosing container on hover of the title
  return (
    <>
      <TitleContainer>
        <Text>{card.title}</Text>
        <VotingButtonsWrapper className="voting-buttons-wrapper">
          <CopilotVotingButtons
            itemId={card.id}
            topicItemId={card.id}
            topicId={card.id}
            highlightedItemId={pageStore.copilotView.highlightedRowId}
            highlightedTopicId={pageStore.copilotView.highlightedTopicId}
            feedbackClass="topic"
            alwaysVisible={true}
          />
        </VotingButtonsWrapper>
      </TitleContainer>
      <CardContents>
        {showContents && <TopicItemRows rows={rows} store={pageStore.copilotView} />}
        {card.items.length > MAX_ITEMS_TO_SHOW && (
          <SeeMoreButton onClick={() => store.sidePanel.goToCopilot(card)}>
            See {numItemsMore} more
          </SeeMoreButton>
        )}
      </CardContents>
    </>
  );
});

const TitleContainer = styled.div(({ theme }) => ({
  alignItems: `center`,
  display: `flex`,
  justifyContent: `space-between`,
  gap: theme.spacing.xs,
  padding: `${theme.spacing.lg} ${theme.spacing.sm} ${theme.spacing.sm} ${theme.spacing.sm}`,
  "&:hover .voting-buttons-wrapper": {
    opacity: 1,
  },
}));

const VotingButtonsWrapper = styled.div`
  opacity: 0;
`;

const Text = styled.span(({ theme }) => ({
  color: theme.colors.grey.x600,
  fontSize: theme.fontSizes.small,
  fontWeight: theme.fontWeights.semiBold,
  lineHeight: theme.lineHeights.xsmall,
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
}));

const SeeMoreButton = styled.button(({ theme }) => ({
  color: theme.colors.grey.x500,
  fontSize: theme.fontSizes.small,
  fontWeight: theme.fontWeights.regular,
  lineHeight: theme.lineHeights.xsmall,
  marginInline: theme.spacing.sm,
  marginTop: theme.spacing.sm,
  padding: 0,
  "&:hover": {
    textDecoration: `underline`,
  },
}));

const CardContents = styled.div(({ theme }) => ({
  paddingBottom: theme.spacing.sm,
}));
