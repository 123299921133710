import { computed, makeObservable, observable } from "mobx";
import { Uuid } from "@/domains/global/identifiers";
import { AppStore } from "@/store";
import { WithAppStore } from "@/store/types";
import { SpaceAccountTopicObservable } from "@/store/topics/SpaceAccountTopicObservable";
import { SpaceAccountTopicItemObservable } from "@/store/topics/SpaceAccountTopicItemObservable";

export class NoteTopicListObservable {
  private store: AppStore;
  private noteId: Uuid;

  constructor({ store, noteId }: { noteId: Uuid } & WithAppStore) {
    this.store = store;
    this.noteId = noteId;

    makeObservable<this, "store" | "noteId">(this, {
      store: false,
      noteId: observable,
      allTopics: computed,
      allTopicItems: computed,
    });
  }

  get allTopics(): SpaceAccountTopicObservable[] {
    return this.allTopicItems
      .sort((a, b) => a.topicRelevanceScore - b.topicRelevanceScore)
      .map(item => item.spaceAccountTopic)
      .filter((topic): topic is SpaceAccountTopicObservable => topic !== undefined);
  }

  get allTopicItems(): SpaceAccountTopicItemObservable[] {
    return this.store.spaceAccountTopicItems.itemsToSpaceAccountTopicItems.get(this.noteId) ?? [];
  }
}
