import { ChatHeader } from "@/components/chat/ChatHeader";
import { TOP_BAR_HEIGHT } from "@/components/layout/components/constants";
import { Panel } from "@/components/layout/Panel";
import { mdsSpacings } from "@/design-system/foundations/typography";
import { ChatInput } from "@/pages/chat/ChatInput";
import { ChatMessagesList } from "@/pages/chat/list";
import { useAppStore } from "@/store";
import { ChatHistory } from "@/store/chat/ChatHistory";
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

export interface ChatViewProps {
  chatHistory: ChatHistory;
  inSidePanel?: boolean;
}

export const ChatView = observer<ChatViewProps>(function ChatView({
  chatHistory,
  inSidePanel = false,
}) {
  const { store } = useAppStore();
  const [chatInputHeight, setChatInputHeight] = useState(80);

  useEffect(() => {
    const primaryChatConversationId = store.chatConversations.primaryChatConversation?.id;
    if (store && primaryChatConversationId) {
      store.chatConversations.subscribeToLiveChatConversation(primaryChatConversationId);
      return () => {
        store.chatConversations.unsubscribeFromLiveChatConversation(primaryChatConversationId);
      };
    }
  }, [store]);

  return (
    <StyledPanel>
      <ChatHeader inSidePanel={inSidePanel} />
      <ChatMessagesList
        style={{
          overflowX: "hidden",
          transition: "height 0.1s ease-in-out",
          height: `calc(100dvh - ${chatInputHeight}px - ${TOP_BAR_HEIGHT}px - 100px)`,
          marginLeft: mdsSpacings().md,
          marginRight: mdsSpacings().md,
          width: `calc(100% - 2 * ${mdsSpacings().md})`,
        }}
        chatHistory={chatHistory}
        inSidePanel={inSidePanel}
      />
      <StyledChatInput
        context={chatHistory.context}
        getAvailableChips={chatHistory.getAvailableChips}
        onHeight={setChatInputHeight}
        onSubmit={chatHistory.submitChatMessage}
        inSidePanel={inSidePanel}
      />
    </StyledPanel>
  );
});

const StyledPanel = styled(Panel)(({ theme }) => ({
  paddingBottom: theme.spacing.md,
}));

const StyledChatInput = styled(ChatInput)(({ theme }) => ({
  marginLeft: theme.spacing.md,
  marginRight: theme.spacing.md,
  width: `calc(100% - 2 * ${theme.spacing.md})`,
}));
