import { observer } from "mobx-react-lite";
import { RelatedCard } from "@/store/routing/types";
import styled from "@emotion/styled";
import { MdsItemList } from "@/design-system/components/item-list";
import { useAppStore } from "@/store/utils/hooks";
import { MdsPanelHeader } from "@/design-system/components/panel-header/MdsPanelHeader";
import { MdsTextSize, MdsTextWeight } from "@/design-system/components/text/types";
import { mdsColors } from "@/design-system/foundations/colors";
import { Panel } from "@/components/layout/Panel";

interface Props {
  card: RelatedCard;
}

export const ExpandedRelatedCard = observer<Props>(function Card({ card }) {
  const { store } = useAppStore();
  return (
    <StyledPanel>
      <MdsPanelHeader
        icon={null}
        onBackClick={store.sidePanel.goUp}
        heading={card.title}
        subheading={`${card.items.length} items`}
        headingTextProps={{
          size: MdsTextSize.Small,
          lineHeight: MdsTextSize.XSmall,
          weight: MdsTextWeight.SemiBold,
          color: mdsColors().grey.x600,
        }}
      />
      <PanelContent>
        <MdsItemList rows={card.items} />
      </PanelContent>
    </StyledPanel>
  );
});

const StyledPanel = styled(Panel)(({ theme }) => ({
  paddingBottom: theme.spacing.sm,
}));

const PanelContent = styled.div(({ theme }) => ({
  padding: theme.spacing.sm,
}));
