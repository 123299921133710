import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { action, computed, observable, makeObservable, reaction } from "mobx";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import {
  MdsItemDropdown,
  MdsItemKind,
  MdsItemListRowData,
  MdsItemListRowType,
  MdsItemListSize,
} from "@/design-system/components/item-list/types";
import {
  MdsDropdownContentList,
  MdsDropdownItem,
  MdsDropdownItemKind,
} from "@/design-system/components/dropdown";
import { MdsIconKind } from "@/design-system/components/icon";
import { uuidModule } from "@/modules/uuid";
import { ShareSheetEntityKind } from "@/components/modal/share-sheet/types";
import { actions } from "@/actions";
import { DeleteCollectionModalStore } from "@/components/modal/delete-collection/DeleteCollectionModalStore";
import { isEmpty } from "lodash-es";
import { ItemPreviewState } from "@/design-system/components/item-list/rows/item-preview/ItemPreviewState";
import { ShareSheetModalStore } from "@/components/modal/share-sheet/ShareSheetModalStore";
import { groupLens, TimelineWithInboxStatusItemFull } from "@/modules/timeline";
import { CollectionItemSubtitle } from "@/components/collection-item-subtitle";
import { LensKind, SortByKind } from "@/modules/lenses/types";
import { collectionsLensModule, lensModule } from "@/modules/lenses";
import localDb from "@/domains/local-db";

export interface CollectionsListPageParams {
  sortBy: SortByKind;
  lens: LensKind;
}
const DEFAULT_SORT_BY = SortByKind.LastModified;
const DEFAULT_LENS = LensKind.All;

export class CollectionsListPageStore extends AppSubStore {
  params: CollectionsListPageParams;

  deleteCollectionModal: DeleteCollectionModalStore;
  shareSheetModal: ShareSheetModalStore;

  currentCollectionId: string | undefined;
  collectionsFilterQuery: string = "";

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);

    this.params = { sortBy: DEFAULT_SORT_BY, lens: DEFAULT_LENS };
    this.deleteCollectionModal = new DeleteCollectionModalStore(injectedDeps, {});
    this.shareSheetModal = ShareSheetModalStore.forAppStore({ appStore: this.store });

    makeObservable<this, "generateItemDropdown">(this, {
      deleteCollectionModal: false,
      shareSheetModal: false,
      currentCollectionId: observable,
      collectionsFilterQuery: observable,

      collection: computed,
      setCurrentCollectionId: action,
      generateItemDropdown: false,
      shouldShowEmptyState: computed,
      allCollections: computed,
      allCollectionRows: computed,
      getGroupCriteria: false,
      filteredCollectionRows: computed,
      mapCollectionToRow: false,
      orderedItemIds: computed,

      // FILTERING
      setCollectionsFilterQuery: action,
      shouldApplyCollectionsFilterQuery: computed,
      normalizedCollectionsFilterQuery: computed,

      // LENSES + PARAMS
      params: observable,
      setParams: action,
      setLens: action,
      setSortBy: action,
      sortOptions: computed,
      lens: computed,
      sortBy: computed,
      sortLabel: computed,

      // ACTIONS
      handleCreateNewCollection: action,
      resetState: action,
      initialize: action,
    });

    reaction(
      () => this.params,
      params => localDb.settings.setCollectionsListPageParams(params)
    );
  }

  get collection(): CollectionObservable | undefined {
    if (!this.currentCollectionId) return;
    return this.store.collections.getCollectionObservableById({
      collectionId: this.currentCollectionId,
    });
  }

  setCurrentCollectionId(collectionId: string | undefined) {
    this.currentCollectionId = collectionId;
  }

  private generateItemDropdown({
    collectionObservable,
  }: {
    collectionObservable: CollectionObservable;
  }): MdsItemDropdown {
    const ownerActions: MdsDropdownItem[] = collectionObservable.isOwnedByMe
      ? [
          {
            id: `delete-${collectionObservable.id}`,
            kind: MdsDropdownItemKind.Button,
            iconKind: MdsIconKind.Trash,
            label: "Delete",
            onClick: () => {
              this.deleteCollectionModal.open({ collectionId: collectionObservable.id });
            },
          },
        ]
      : [];
    return {
      items: [
        {
          id: `copy-link-${collectionObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          label: "Copy link",
          onClick: () => {
            actions.copyCollectionLinkToClipboard({ collectionId: collectionObservable.id });
          },
          iconKind: MdsIconKind.Copy,
        },
        {
          id: `share-${collectionObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: MdsIconKind.Share,
          label: "Share",
          onClick: () =>
            this.shareSheetModal.open({
              id: collectionObservable.id,
              entityKind: ShareSheetEntityKind.Collection,
            }),
        },
        ...ownerActions,
      ],
    };
  }

  get shouldShowEmptyState() {
    return isEmpty(this.allCollectionRows);
  }

  get allCollections(): CollectionObservable[] {
    return this.store.collections.allCollections;
  }

  get allCollectionRows(): MdsItemListRowData[] {
    return this.allCollections
      .sort(collectionsLensModule.sortFunctionMap[this.sortBy])
      .map(collection => this.mapCollectionToRow(collection));
  }

  getGroupCriteria(collection: CollectionObservable): string {
    switch (this.sortBy) {
      case SortByKind.LastModified:
        return collection.modifiedAt;
      case SortByKind.LastCreated:
        return collection.createdAt;
      case SortByKind.LastViewed:
        return collection.spaceAccountCollection?.lastViewedAt || collection.createdAt;
      case SortByKind.Alphabetical:
        return "";
    }
  }

  get filteredCollectionRows(): MdsItemListRowData[] {
    const rows: TimelineWithInboxStatusItemFull[] = this.allCollections
      .filter(collection => {
        if (!collection?.isAvailable) return false;
        if (!this.shouldApplyCollectionsFilterQuery) return true;
        const normalizedCollectionLabel = collection.label.toLowerCase();
        return normalizedCollectionLabel.includes(this.normalizedCollectionsFilterQuery);
      })
      .filter(collectionsLensModule.filterFunctionMap[this.lens])
      .sort(collectionsLensModule.sortFunctionMap[this.sortBy])
      .map(collection => ({
        kind: "full",
        item: collection,
        dateTime: new Date(this.getGroupCriteria(collection)),
      }));

    const output: MdsItemListRowData[] = [];

    if (this.sortBy === SortByKind.Alphabetical) {
      rows.forEach(row => {
        if (row.item instanceof CollectionObservable) {
          output.push(this.mapCollectionToRow(row.item));
        }
      });
    } else {
      groupLens(rows).forEach(group => {
        output.push({
          type: MdsItemListRowType.SectionHeader,
          key: group.title,
          payload: { title: group.title },
        });
        group.items.forEach(item => {
          if (item.kind === "full" && item.item instanceof CollectionObservable) {
            output.push(this.mapCollectionToRow(item.item));
          }
        });
      });
    }

    output.push({
      type: MdsItemListRowType.Padding,
      key: "padding",
      payload: { height: 50 },
    });

    return output;
  }

  mapCollectionToRow(collection: CollectionObservable): MdsItemListRowData {
    return {
      type: MdsItemListRowType.Item,
      key: collection.id,
      size: MdsItemListSize.XLarge,
      payload: {
        id: collection.id,
        kind: MdsItemKind.Collection,
        titleIcon: collection.sharedAt ? { kind: MdsIconKind.Shared } : undefined,
        createPreviewState: () =>
          new ItemPreviewState({
            store: this.store,
            id: collection.id,
            kind: MdsItemKind.Collection,
          }),
        label: collection.label,
        onClick: () => this.store.navigation.goToCollection({ collectionId: collection.id }),
        sharedBy: collection.sharedBy,
        dateLabel: collectionsLensModule.dateLabelMap[this.sortBy](collection),
        dropdown: this.generateItemDropdown({ collectionObservable: collection }),
        action: undefined,
        extraRows: [
          {
            id: "collection-description",
            content: () => (
              <CollectionItemSubtitle
                count={collection.itemList.size}
                description={collection.description}
              />
            ),
          },
        ],
      },
    };
  }

  get orderedItemIds() {
    return this.allCollectionRows.map(row => row.key);
  }

  get shouldApplyCollectionsFilterQuery() {
    return !isEmpty(this.normalizedCollectionsFilterQuery);
  }

  get normalizedCollectionsFilterQuery() {
    return this.collectionsFilterQuery.trim().toLowerCase();
  }

  setCollectionsFilterQuery({ query }: { query: string }) {
    this.collectionsFilterQuery = query;
  }

  handleCreateNewCollection = () => {
    const collectionId = uuidModule.generate();
    this.store.navigation.goToCollection({ collectionId });
    // Delay create collection to after navigation to prevent layout shift in list view
    setTimeout(() => {
      this.store.collections.createCollection({
        collectionId,
        title: "",
        description: "",
      });
    }, 0);
  };

  resetState = () => {
    this.collectionsFilterQuery = "";
  };

  // PARAMS + LENSES
  setParams = (params: CollectionsListPageParams) => {
    this.params = params;
  };

  setLens = (lens: LensKind) => {
    this.setParams({ ...this.params, lens });
  };

  setSortBy = ({ itemId }: { itemId: string }) => {
    this.setParams({ ...this.params, sortBy: itemId as SortByKind });
  };

  get lens() {
    return this.params.lens;
  }

  get sortBy() {
    return this.params.sortBy;
  }

  get sortLabel(): string {
    return lensModule.sortKindLabelMap[this.sortBy];
  }

  get sortOptions(): MdsDropdownContentList {
    return {
      items: [
        {
          id: "sort-by-divider",
          kind: MdsDropdownItemKind.Detail,
          text: "Sort by",
        },
        {
          id: SortByKind.LastModified,
          kind: MdsDropdownItemKind.Button,
          label: lensModule.sortKindLabelMap[SortByKind.LastModified],
          isChecked: this.sortBy === SortByKind.LastModified,
          onClick: this.setSortBy,
        },
        {
          id: SortByKind.LastCreated,
          kind: MdsDropdownItemKind.Button,
          label: lensModule.sortKindLabelMap[SortByKind.LastCreated],
          isChecked: this.sortBy === SortByKind.LastCreated,
          onClick: this.setSortBy,
        },
        {
          id: SortByKind.LastViewed,
          kind: MdsDropdownItemKind.Button,
          label: lensModule.sortKindLabelMap[SortByKind.LastViewed],
          isChecked: this.sortBy === SortByKind.LastViewed,
          onClick: this.setSortBy,
        },
        {
          id: SortByKind.Alphabetical,
          kind: MdsDropdownItemKind.Button,
          label: lensModule.sortKindLabelMap[SortByKind.Alphabetical],
          isChecked: this.sortBy === SortByKind.Alphabetical,
          onClick: this.setSortBy,
        },
      ],
    };
  }

  initialize = async () => {
    const params = await localDb.settings.getCollectionsListPageParams();
    this.setParams({
      sortBy: params.sortBy || DEFAULT_SORT_BY,
      lens: params.lens || DEFAULT_LENS,
    });
  };
}
