import { SpaceAccountTopicItemObservable } from "@/store/topics/SpaceAccountTopicItemObservable";
import { SpaceAccountTopicItemModelData } from "@/store/topics/types";
import { makeObservable, computed, override } from "mobx";
import { AppSubStoreArgs } from "@/store/types";
import { Uuid } from "@/domains/global/identifiers";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { SyncModelKind, SyncUpdateValue } from "@/store/sync/types";
import { SpaceAccountTopicObservable } from "@/store/topics/SpaceAccountTopicObservable";

export class AppStoreSpaceAccountTopicItemsStore extends BaseSyncModelStore<
  SpaceAccountTopicItemObservable,
  SpaceAccountTopicItemModelData
> {
  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.SpaceAccountTopicItem, ...injectedDeps });
    makeObservable(this, {
      createSyncModel: false,
      topicsToSpaceAccountTopicItems: computed,
      itemsToSpaceAccountTopicItems: computed,
      itemsToSpaceAccountTopics: computed,
      resetSync: override,
    });
  }

  createSyncModel(
    data: SyncUpdateValue<SpaceAccountTopicItemModelData>
  ): SpaceAccountTopicItemObservable {
    return new SpaceAccountTopicItemObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }

  get topicsToSpaceAccountTopicItems(): Map<Uuid, SpaceAccountTopicItemObservable[]> {
    const output = new Map<Uuid, SpaceAccountTopicItemObservable[]>();
    for (const spaceAccountTopicItem of this.pool.values()) {
      if (output.has(spaceAccountTopicItem.spaceAccountTopicId)) {
        output.get(spaceAccountTopicItem.spaceAccountTopicId)?.push(spaceAccountTopicItem);
      } else {
        output.set(spaceAccountTopicItem.spaceAccountTopicId, [spaceAccountTopicItem]);
      }
    }
    return output;
  }

  get itemsToSpaceAccountTopicItems(): Map<Uuid, SpaceAccountTopicItemObservable[]> {
    const output = new Map<Uuid, SpaceAccountTopicItemObservable[]>();
    for (const spaceAccountTopicItem of this.pool.values()) {
      if (output.has(spaceAccountTopicItem.itemId)) {
        output.get(spaceAccountTopicItem.itemId)?.push(spaceAccountTopicItem);
      } else {
        output.set(spaceAccountTopicItem.itemId, [spaceAccountTopicItem]);
      }
    }
    return output;
  }

  get itemsToSpaceAccountTopics(): Map<Uuid, SpaceAccountTopicObservable[]> {
    const output = new Map<Uuid, SpaceAccountTopicObservable[]>();
    for (const spaceAccountTopicItem of this.pool.values()) {
      if (!spaceAccountTopicItem.spaceAccountTopic) continue;
      if (output.has(spaceAccountTopicItem.itemId)) {
        output.get(spaceAccountTopicItem.itemId)?.push(spaceAccountTopicItem.spaceAccountTopic);
      } else {
        output.set(spaceAccountTopicItem.itemId, [spaceAccountTopicItem.spaceAccountTopic]);
      }
    }
    return output;
  }

  public resetSync() {
    this.remotePool.clear();
  }
}
