import { action, makeObservable, observable, computed, reaction } from "mobx";
import { appRoutes } from "@/app/router";
import { AccountProfileImage } from "@/components/layout/components/account-profile/AccountProfileImage";
import {
  ShareChip,
  ShareSheetChipType,
  ShareSheetEntityKind,
} from "@/components/modal/share-sheet/types";
import { MdsChipInputProps } from "@/design-system/components/input/MdsChipInput";
import {
  SyncModelPermissionEntry,
  SyncModelPermissionEntryWithStatus,
} from "@/domains/sync-scopes/types";
import { UNTITLED_COLLECTION_TITLE } from "@/domains/untitled/untitled";
import { isEmail } from "@/domains/validation/validation";
import { copyTextToClipboard } from "@/modules/clipboard/copyTextToClipboard";
import { toastModule } from "@/modules/toast";
import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";
import { Uuid } from "@/domains/global/identifiers";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { CollectionIcon } from "@/components/collection/CollectionIcon";
import { INoteObservable } from "@/store/note/types";
import { sortSyncScopePermissionEntries } from "@/domains/sync-scopes";
import { AppStore, GuestAppStore } from "@/store";
import { Maybe } from "@/domains/common/types";
import { ContactObservable } from "@/store/contacts/ContactObservable";
import { MakeCollectionPrivateShareSheetModalStore } from "@/components/modal/share-sheet/MakeCollectionPrivateShareSheetModalStore";
import { isEqual } from "lodash-es";
import { ModalDefinitionKind, ShareSheetModalDefinition } from "@/store/modals/types";
import { ProfileKind, ProfileSize } from "@/components/layout/components/account-profile";
import { windowModule } from "@/modules/window";

export type GetCollection = (_: { collectionId?: Uuid }) => Maybe<CollectionObservable>;
export type GetNote = (_: { noteId?: Uuid }) => Maybe<INoteObservable>;
export type GetMatchingContacts = (query: string) => ContactObservable[];
export type GetMatchingCollections = (query: string) => CollectionObservable[];

enum ORDER_PRIORITY_PREFIX {
  HIGH = "2-",
  MEDIUM = "1-",
  LOW = "0-",
}

export class ShareSheetModalStore {
  private store: AppStore | GuestAppStore;
  private getCollection?: GetCollection;
  private getNote?: GetNote;
  private getMatchingContacts?: GetMatchingContacts;
  private getMatchingCollections?: GetMatchingCollections;

  public makeCollectionPrivateModal: MakeCollectionPrivateShareSheetModalStore;

  isGuestMode: boolean;
  id: string | undefined;
  entityKind: ShareSheetEntityKind | undefined;
  chips: ShareChip[] = [];

  isOpen: boolean = false;
  onClose?: () => void;

  isSharing: boolean = false;
  isUnsharing: boolean = false;

  private constructor({
    store,
    isGuestMode,
    getCollection,
    getNote,
    getMatchingContacts,
    getMatchingCollections,
  }: {
    store: AppStore | GuestAppStore;
    isGuestMode: boolean;
    getCollection?: GetCollection;
    getNote?: GetNote;
    getMatchingContacts?: GetMatchingContacts;
    getMatchingCollections?: GetMatchingCollections;
  }) {
    this.store = store;
    this.isGuestMode = isGuestMode;
    this.getCollection = getCollection;
    this.getNote = getNote;
    this.getMatchingContacts = getMatchingContacts;
    this.getMatchingCollections = getMatchingCollections;

    this.makeCollectionPrivateModal = new MakeCollectionPrivateShareSheetModalStore({
      store,
      getCollection,
    });

    makeObservable<
      this,
      "store" | "getCollection" | "getNote" | "getMatchingContacts" | "getMatchingCollections"
    >(this, {
      store: false,
      getCollection: false,
      getNote: false,
      getMatchingContacts: false,
      getMatchingCollections: false,
      makeCollectionPrivateModal: false,

      isGuestMode: observable,
      id: observable,
      entityKind: observable,
      chips: observable,
      isOpen: observable,
      onClose: observable,
      isSharing: observable,
      isUnsharing: observable,

      modalDefinition: computed,
      modal: computed,
      open: action,
      close: action,

      collection: computed,
      note: computed,
      target: computed,
      aclEntries: computed,

      getChips: false,
      setChips: action,
      handleSubmit: action,
      handleGrantAccess: action,
      canUpdateAccess: computed,
      handleUpdateAccess: action,
      canRevokeAccess: computed,
      handleRevokeAccess: action,
      handleCopyCollaborativeLink: action,
      handleCancelOperation: action,
    });

    reaction(
      () => this.modalDefinition,
      (current, previous) => {
        if (!(this.store instanceof AppStore) || isEqual(current, previous)) return;

        if (previous) this.store.modals.removeModal(previous);
        if (current) this.store.modals.addModal(current);
      }
    );

    reaction(
      () => this.target?.canAccess,
      (canAccess, previousCanAccess) => {
        if (!canAccess && canAccess !== previousCanAccess) this.close();
      }
    );
  }

  static forAppStore({ appStore }: { appStore: AppStore }) {
    return new ShareSheetModalStore({
      store: appStore,
      isGuestMode: false,
      getCollection: appStore.collections.getCollectionObservableById,
      getNote: appStore.notes.getNoteObservableById,
      getMatchingContacts: appStore.contacts.getMatchingContacts,
      getMatchingCollections: appStore.collections.getMatchingSharedCollections,
    });
  }

  static forGuestStore({ guestStore }: { guestStore: GuestAppStore }) {
    return new ShareSheetModalStore({
      store: guestStore,
      isGuestMode: true,
      getNote: guestStore.note.getNoteObservableById,
    });
  }

  get modalDefinition(): Maybe<ShareSheetModalDefinition> {
    if (!this.isOpen) return;

    return {
      kind: ModalDefinitionKind.ShareSheet,
      store: this,
    };
  }

  get modal(): Maybe<ShareSheetModalStore> {
    if (this.store instanceof GuestAppStore) {
      return this.isOpen ? this : undefined;
    }
    // Use modals because there might be another error queued in front of it.
    const store = this.store.modals.shareSheet;
    return store === this ? store : undefined;
  }

  open = ({ id, entityKind }: { id: Uuid; entityKind: ShareSheetEntityKind }) => {
    this.chips = [];
    this.id = id;
    this.entityKind = entityKind;
    this.isOpen = true;
  };

  close = () => {
    this.id = undefined;
    this.entityKind = undefined;
    this.onClose?.();
    this.isOpen = false;
  };

  get collection(): Maybe<CollectionObservable> {
    if (this.entityKind !== ShareSheetEntityKind.Collection) return undefined;
    return this.getCollection?.({ collectionId: this.id });
  }

  get note(): Maybe<INoteObservable> {
    if (this.entityKind !== ShareSheetEntityKind.Note) return undefined;
    return this.getNote?.({ noteId: this.id });
  }

  get target() {
    return this.collection ?? this.note;
  }

  get aclEntries(): SyncModelPermissionEntryWithStatus[] {
    if (this.entityKind === ShareSheetEntityKind.Collection)
      return sortSyncScopePermissionEntries(this.store, this.collection?.permissions || []);
    if (this.entityKind === ShareSheetEntityKind.Note)
      return sortSyncScopePermissionEntries(this.store, this.note?.permissions || []);
    return [];
  }

  getChips = (query: string): ShareChip[] => {
    const output: ShareChip[] = [];

    const contacts = this.getMatchingContacts?.(query) ?? [];
    for (const contact of contacts) {
      if (this.target?.grantedSpaceAccountIds.includes(contact.contactSpaceAccountId)) continue;

      output.push({
        id: contact.contactSpaceAccountId,
        type: ShareSheetChipType.SpaceAccount,
        orderByString: `${ORDER_PRIORITY_PREFIX.HIGH}${contact.lastSharedWithAt}`,
        label: contact.profileDisplayName,
        displayName: contact.profileDisplayName,
        secondaryLabel: contact.profileEmailAddress,
        photoUrl: contact.profilePhotoUrl ?? undefined,
        icon: (size?: number) => (
          <AccountProfileImage
            size={size === 34 ? ProfileSize.Large : ProfileSize.Small}
            profile={{
              kind: ProfileKind.Contact,
              data: contact,
            }}
          />
        ),
        searchLabels: [contact.profileDisplayName, contact.profileEmailAddress],
      });
    }

    const email = query.trim();
    if (
      isEmail(email) &&
      !this.target?.grantedEmailAddresses.includes(email) &&
      output.length === 0
    ) {
      output.push({
        id: email,
        type: ShareSheetChipType.Email,
        orderByString: `${ORDER_PRIORITY_PREFIX.LOW}${new Date().toISOString()}`, // Display last
        label: `Invite ${email}`,
        displayName: email,
        icon: (_size?: number) => <MdsIcon kind={MdsIconKind.Envelope} />,
        searchLabels: [email],
      });
    }

    // Early return because we can't share collections with other collections
    if (this.entityKind === ShareSheetEntityKind.Collection) return output;

    const collections = this.getMatchingCollections?.(query) ?? [];
    for (const collection of collections) {
      if (this.target?.grantedCollectionIds.includes(collection.id)) continue;

      output.push({
        id: collection.id,
        type: ShareSheetChipType.Collection,
        orderByString: collection.lastInteractedAtByMe
          ? `${ORDER_PRIORITY_PREFIX.HIGH}${collection.lastInteractedAtByMe}`
          : `${ORDER_PRIORITY_PREFIX.MEDIUM}${collection.lastInteractedAt}`,
        label: collection.title || UNTITLED_COLLECTION_TITLE,
        displayName: collection.title || UNTITLED_COLLECTION_TITLE,
        secondaryLabel: collection.description, // TODO: Do we need to set max width on this?
        photoUrl: undefined,
        icon: (size?: number) => <CollectionIcon collectionId={collection.id} size={size} />,
        searchLabels: [collection.title || UNTITLED_COLLECTION_TITLE],
      });
    }

    return output;
  };

  setChips: MdsChipInputProps["setChips"] = chips => {
    const newChips = (typeof chips === "function" ? chips(this.chips) : chips) as ShareChip[];
    this.chips = newChips.map(chip =>
      chip.type === ShareSheetChipType.Email ? { ...chip, label: chip.displayName } : chip
    );
  };

  handleSubmit = async () => {
    this.chips.map(this.handleGrantAccess);
    this.chips = [];
  };

  handleGrantAccess = (chip: ShareChip) => {
    switch (chip.type) {
      case ShareSheetChipType.Email: {
        if (this.target?.grantedEmailAddresses.includes(chip.id)) break;

        this.target?.grantAccessViaEmailAddress({
          targetEmailAddress: chip.id,
          roleKind: "EDITOR",
        });
        break;
      }
      case ShareSheetChipType.SpaceAccount: {
        if (this.target?.grantedSpaceAccountIds.includes(chip.id)) break;

        this.target?.grantAccessViaSpaceAccount({
          targetSpaceAccountId: chip.id,
          roleKind: "EDITOR",
        });
        break;
      }
      case ShareSheetChipType.Collection: {
        if (this.target?.grantedCollectionIds.includes(chip.id)) break;

        this.note?.collectionList?.addCollection({
          collectionId: chip.id,
        });
        break;
      }
    }
  };

  get canUpdateAccess() {
    if (this.entityKind === ShareSheetEntityKind.Note) {
      return !!this.note?.updateAccessViaEmailAddress;
    }
    return true;
  }

  handleUpdateAccess = async (permission: SyncModelPermissionEntry) => {
    if (permission.role_kind !== "EDITOR" && permission.role_kind !== "VIEWER") return;
    if (this.entityKind === ShareSheetEntityKind.Note && this.note) {
      if (permission.scope_kind === "EMAIL_SCOPE") {
        this.note.updateAccessViaEmailAddress({
          targetEmailAddress: permission.email_address,
          roleKind: permission.role_kind,
        });
      }
      if (permission.scope_kind === "SPACE_ACCOUNT_SCOPE") {
        this.note.updateAccessViaSpaceAccount({
          targetSpaceAccountId: permission.space_account_id,
          roleKind: permission.role_kind,
        });
      }
      if (permission.scope_kind === "COLLECTION_SCOPE") {
        this.note.updateAccessViaCollection({
          collectionId: permission.collection_id,
          roleKind: permission.role_kind,
        });
      }
      if (permission.scope_kind === "GUEST_ACCOUNT_SCOPE") {
        // TODO: We need a UpdateAccessViaGuestAccount operation.
      }
    }
    if (this.entityKind === ShareSheetEntityKind.Collection && this.collection) {
      if (permission.scope_kind === "EMAIL_SCOPE") {
        this.collection.updateAccessViaEmailAddress({
          targetEmailAddress: permission.email_address,
          roleKind: permission.role_kind,
        });
      }
      if (permission.scope_kind === "SPACE_ACCOUNT_SCOPE") {
        this.collection.updateAccessViaSpaceAccount({
          targetSpaceAccountId: permission.space_account_id,
          roleKind: permission.role_kind,
        });
      }
      if (permission.scope_kind === "GUEST_ACCOUNT_SCOPE") {
        // TODO: We need a UpdateAccessViaGuestAccount operation.
      }
    }
  };

  get canRevokeAccess() {
    // TODO: What is this for?
    if (this.entityKind === ShareSheetEntityKind.Note) {
      return !!this.note?.revokeAccessViaEmailAddress;
    }
    return true;
  }

  handleRevokeAccess = async (permission: SyncModelPermissionEntry) => {
    if (this.entityKind === ShareSheetEntityKind.Note && this.note) {
      if (permission.scope_kind === "EMAIL_SCOPE") {
        this.note.revokeAccessViaEmailAddress({
          targetEmailAddress: permission.email_address,
        });
      }
      if (permission.scope_kind === "SPACE_ACCOUNT_SCOPE") {
        this.note.revokeAccessViaSpaceAccount({
          targetSpaceAccountId: permission.space_account_id,
        });
      }
      if (permission.scope_kind === "COLLECTION_SCOPE") {
        this.note.collectionList?.removeCollection({
          collectionId: permission.collection_id,
        });
      }
      if (permission.scope_kind === "GUEST_ACCOUNT_SCOPE") {
        // TODO: We need a RevokeAccessViaGuestAccount operation.
      }
    }
    if (this.entityKind === ShareSheetEntityKind.Collection && this.collection) {
      if (permission.scope_kind === "EMAIL_SCOPE") {
        this.collection.revokeAccessViaEmailAddress({
          targetEmailAddress: permission.email_address,
        });
      }
      if (permission.scope_kind === "SPACE_ACCOUNT_SCOPE") {
        this.collection.revokeAccessViaSpaceAccount({
          targetSpaceAccountId: permission.space_account_id,
        });
      }
      if (permission.scope_kind === "GUEST_ACCOUNT_SCOPE") {
        // TODO: We need a RevokeAccessViaGuestAccount operation.
      }
    }
  };

  handleCopyCollaborativeLink = async () => {
    if (!this.id || !this.entityKind) return;

    if (this.entityKind === ShareSheetEntityKind.Collection) {
      const targetUrl = windowModule.buildUrl({
        path: appRoutes.collectionsView({ params: { collectionId: this.id } }).path,
      });

      await copyTextToClipboard({
        text: targetUrl.toString(),
      });
    }
    if (this.entityKind === ShareSheetEntityKind.Note) {
      const targetUrl = windowModule.buildUrl({
        path: appRoutes.notesView({ params: { noteId: this.id } }).path,
      });

      await copyTextToClipboard({
        text: targetUrl.toString(),
      });
    }

    toastModule.triggerToast({ content: "Copied collaborative link to clipboard" });
  };

  handleCancelOperation = async (syncOperationId: string) => {
    const success = this.store.sync.actionQueue.skipAndRevertOperationById(syncOperationId);
    if (success) toastModule.triggerToast({ content: "Cancelled pending access update." });
    else toastModule.triggerToast({ content: "Failed to cancel access update." });
  };
}
