import table from "@/domains/local-db/account/settings/adapter";
import config from "@/domains/local-db/account/settings/config";
import { CollectionsListPageParams } from "@/store/pages/CollectionsListPageStore/CollectionsListPageStore";
import { CollectionsViewPageParams } from "@/store/pages/CollectionsViewPageStore/CollectionsViewPageStore";
import { NotesListPageParams } from "@/store/pages/NotesListPageStore";
import { SidePanelRoute, SidePanelState, SidePanelTab } from "@/store/routing/types";

export const setSidePanelState = async (state: SidePanelState) => {
  return table.set(config.SIDE_PANEL_STATE_KEY, {
    [config.SIDE_PANEL_STATE_KEY]: state,
  });
};

export const setSidePanelActiveTabId = async (sidePanelActiveTab: SidePanelTab) => {
  return table.set(config.SIDE_PANEL_ACTIVE_TAB_ID_KEY, {
    [config.SIDE_PANEL_ACTIVE_TAB_ID_KEY]: sidePanelActiveTab,
  });
};

export const setSidePanelTabs = async (tabs: [SidePanelTab, SidePanelRoute[]][]) => {
  const tabsString = JSON.stringify(tabs);
  return table.set(config.SIDE_PANEL_TABS_KEY, {
    [config.SIDE_PANEL_TABS_KEY]: tabsString,
  });
};

export const setNotesListPageParams = async (params: NotesListPageParams) => {
  const paramsString = JSON.stringify(params);
  return table.set(config.NOTES_LIST_PAGE_PARAMS_KEY, {
    [config.NOTES_LIST_PAGE_PARAMS_KEY]: paramsString,
  });
};

export const setCollectionsListPageParams = async (params: CollectionsListPageParams) => {
  const paramsString = JSON.stringify(params);
  return table.set(config.COLLECTIONS_LIST_PAGE_PARAMS_KEY, {
    [config.COLLECTIONS_LIST_PAGE_PARAMS_KEY]: paramsString,
  });
};

export const setCollectionsViewPageParams = async (
  collectionId: string,
  params: CollectionsViewPageParams
) => {
  const paramsString = JSON.stringify(params);
  return table.set(config.getCollectionsViewPageParamsKey(collectionId), {
    [config.COLLECTIONS_VIEW_PAGE_PARAMS_KEY]: paramsString,
  });
};
