import { type ChangeEventHandler, type PropsWithChildren, useCallback, useState } from "react";
import {
  makeSimpleCollection,
  OrganizeComboBox,
  SimpleCollection,
} from "@/components/note/editor/top-bar/organize";
import { useSearchSuggestions } from "@/domains/search/suggestions/SearchSuggestions";
import { type INoteObservable } from "@/store/note/types";
import { actions } from "@/actions";
import { useAppStore } from "@/store/utils/hooks";

interface OrganizeProps extends PropsWithChildren, OrganizeNoteState {
  note: INoteObservable;
}
export const OrganizeNote = ({
  note,
  children,
  organizeIsOpen,
  setOrganizeIsOpen,
}: OrganizeProps) => {
  const { store } = useAppStore();
  const [organizeSearchText, setOrganizeSearchText] = useState("");
  const [showCreateCollection, setShowCreateCollection] = useState(false);
  const searchSuggestions = useSearchSuggestions();
  const simpleCollections: SimpleCollection[] =
    organizeSearchText.length === 0
      ? store.recentItems.sortedRecentCollectionsInteractedWithByMe.map(makeSimpleCollection) // no search entered
      : searchSuggestions
          .search(organizeSearchText, store.collections.allDataForSearch)
          .toSorted((a, b) => b.sortKey - a.sortKey)
          .map(result => {
            return makeSimpleCollection(
              store.collections.getCollectionObservableById({
                collectionId: result.id,
              })! // since this collection id just came from searchSuggestions, we know it exists
            );
          });

  const organizeCollectionsHeader =
    simpleCollections.length === 0
      ? null
      : organizeSearchText.trim().length > 0
        ? "Collections"
        : "Recent collections";

  const handleSearchCollections: ChangeEventHandler<HTMLInputElement> = e => {
    setOrganizeSearchText(e.target.value);

    if (e.target.value.length >= 2) {
      setShowCreateCollection(true);
    } else {
      setShowCreateCollection(false);
    }
  };

  const handleClearSearchCollections = () => {
    setOrganizeSearchText("");
    setShowCreateCollection(false);
  };

  const handleSelectCollection = async (collectionId: string) => {
    if (!note) return;
    const collection = store.collections.getCollectionObservableById({ collectionId });
    if (!collection) return;

    if (note.collectionList?.hasCollection({ collectionId: collection.id })) {
      await actions.removeNoteFromCollection({
        note,
        collection,
        store,
      });
    } else {
      await actions.addNotesToCollection({
        notes: [note],
        collection,
        store,
      });
    }

    handleOrganizeIsOpenChange(false);
  };

  const handleCreateNewCollection = async (collectionName: string) => {
    if (!note) return;

    await actions.createCollection({
      title: collectionName,
      store,
      notes: [note],
    });

    setOrganizeSearchText("");
    setShowCreateCollection(false);
  };

  const handleOrganizeIsOpenChange = (isOpen: boolean) => {
    setOrganizeIsOpen(isOpen);
    if (!isOpen) {
      setOrganizeSearchText("");
      setShowCreateCollection(false);
    }
  };

  const handleClickOrganize = () => {
    handleOrganizeIsOpenChange(!organizeIsOpen);
  };

  return (
    <OrganizeComboBox
      items={simpleCollections}
      isOpen={organizeIsOpen}
      searchText={organizeSearchText}
      header={organizeCollectionsHeader}
      showCreateNewItem={showCreateCollection}
      onInputChange={handleSearchCollections}
      onClear={handleClearSearchCollections}
      onSelectItem={handleSelectCollection}
      onOpenChange={handleOrganizeIsOpenChange}
      onCreateNewItem={handleCreateNewCollection}
    >
      {children}
    </OrganizeComboBox>
  );
};

export const useOrganizeNoteState = () => {
  const [organizeIsOpen, setOrganizeIsOpen] = useState(false);

  const toggleOrganizeIsOpen = useCallback(() => {
    setOrganizeIsOpen(!organizeIsOpen);
  }, [organizeIsOpen]);

  return {
    organizeIsOpen,
    setOrganizeIsOpen,
    toggleOrganizeIsOpen,
  };
};

type OrganizeNoteState = ReturnType<typeof useOrganizeNoteState>;
