import { moduleFactory } from "@/factories/module";
import {
  electronBindingNavigateToExternalUrl,
  electronBindingNavigateToInternalUrl,
  electronBindingFindInPage,
  electronBindingStopFindInPage,
  electronBindingOnFindInPageResult,
} from "@/modules/electron/bindings";

export const electronModule = moduleFactory.create({
  navigateToInternalUrl: electronBindingNavigateToInternalUrl,
  navigateToExternalUrl: electronBindingNavigateToExternalUrl,
  findInPage: electronBindingFindInPage,
  stopFindInPage: electronBindingStopFindInPage,
  onFindInPageResult: electronBindingOnFindInPageResult,
});
