import { observer } from "mobx-react-lite";
import { toastModule } from "@/modules/toast";
import { MdsIconKind } from "@/design-system/components/icon";
import { MdsIconButton } from "@/design-system/components/icon-button";
import { useAppStore } from "@/store/utils/hooks";
import { MdsTooltipPlacement } from "@/design-system/components/tooltip";
import styled from "@emotion/styled";

interface CopilotVotingButtonsProps {
  itemId: string;
  topicId: string;
  topicItemId: string | null;
  highlightedItemId: string | null;
  feedbackClass: string;
  alwaysVisible?: boolean;
  highlightedTopicId: string | null;
}

enum TopicItemVote {
  None,
  Upvote,
  Downvote,
}

export const CopilotVotingButtons = observer<CopilotVotingButtonsProps>(
  function CopilotVotingButtons({
    itemId,
    topicId,
    topicItemId,
    highlightedItemId,
    highlightedTopicId,
    feedbackClass,
    alwaysVisible = false,
  }) {
    const { pageStore } = useAppStore();
    const submitTopicFeedback = pageStore.copilotView.submitTopicFeedback;
    const submitTopicItemFeedback = pageStore.copilotView.submitTopicItemFeedback;

    const isVisible =
      (itemId === highlightedItemId && highlightedTopicId == topicId) || alwaysVisible;

    const handleTopicVote = async (
      event: React.MouseEvent<HTMLButtonElement>,
      voteType: TopicItemVote
    ) => {
      event.preventDefault();
      event.stopPropagation();
      const additionalComments = prompt("Please enter reasoning for vote.") || null;
      if (feedbackClass === "topic") {
        await submitTopicFeedback(voteType, topicId, additionalComments);
        pageStore.copilotView.addToVotedIds(topicId);
      } else {
        if (!topicItemId) return;
        await submitTopicItemFeedback(voteType, topicItemId, additionalComments);
        pageStore.copilotView.addToVotedIds(topicItemId);
      }
      const voteTypeString = voteType === TopicItemVote.Upvote ? "good" : "bad";
      const resultName = feedbackClass === "topic" ? "topic" : "result";
      toastModule.triggerToast({ content: `Marked ${resultName} as ${voteTypeString}` });
    };

    const buttonsDisabled = pageStore.copilotView.votedIds.has(
      feedbackClass === "topic" ? topicId : topicItemId!
    );

    if (!isVisible) return null;
    return (
      <VotingButtonContainer>
        <VotingButton
          iconKind={MdsIconKind.Upvote}
          isDisabled={buttonsDisabled}
          onClick={e => handleTopicVote(e, TopicItemVote.Upvote)}
          tooltipConfig={{
            content: "Mark as good",
            placement: MdsTooltipPlacement.Left,
          }}
        />
        <VotingButton
          iconKind={MdsIconKind.Downvote}
          isDisabled={buttonsDisabled}
          onClick={e => handleTopicVote(e, TopicItemVote.Downvote)}
          tooltipConfig={{
            content: "Mark as bad",
            placement: MdsTooltipPlacement.Left,
          }}
        />
      </VotingButtonContainer>
    );
  }
);

const VotingButton = styled(MdsIconButton)({
  display: "inline-block",
});

const VotingButtonContainer = styled.div({
  display: "flex",
  gap: "5px",
});
