import { actions } from "@/actions";
import { MdsDropdownContentList, MdsDropdownItemKind } from "@/design-system/components/dropdown";
import { MdsIconKind } from "@/design-system/components/icon";
import { mdsColors } from "@/design-system/foundations";
import { css } from "@/domains/emotion";
import { uuidModule } from "@/modules/uuid";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { INoteObservable } from "@/store/note";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { filter } from "lodash-es";
import { action, computed, makeObservable, observable } from "mobx";

export class AddToCollectionModalStore extends AppSubStore {
  private handleConfirm?: () => void;
  public collection?: CollectionObservable;
  public selectedNoteIds: string[] = [];
  public searchQuery: string;
  public keyboardSelectedIndex = -1;

  constructor(injectedDeps: AppSubStoreArgs, { handleConfirm }: { handleConfirm?: () => void }) {
    super(injectedDeps);

    this.handleConfirm = handleConfirm;
    this.searchQuery = "";

    makeObservable<this, "handleConfirm">(this, {
      handleConfirm: false,

      collection: observable,
      selectedNoteIds: observable,
      searchQuery: observable,
      keyboardSelectedIndex: observable,

      isOpen: computed,
      selectedNote: computed,
      selectedNotes: computed,
      dropdownContentList: computed,

      open: action,
      close: action,
      setSearchQuery: action,
      incrementSelectedIndex: action,
      decrementSelectedIndex: action,
      resetSelectedIndex: action,
      onKeyDown: action,
    });
  }

  get isOpen() {
    return this.selectedNoteIds.length > 0;
  }

  get selectedNote(): INoteObservable | undefined {
    return this.selectedNotes[0];
  }

  get selectedNotes() {
    return filter(
      this.selectedNoteIds.map(noteId => this.store.notes.getNoteObservableById({ noteId }))
    ) as INoteObservable[];
  }

  get dropdownContentList(): MdsDropdownContentList {
    const output: MdsDropdownContentList = { items: [] };

    const itemIds = this.selectedNoteIds;
    const items = this.selectedNotes;
    if (!itemIds.length || !items.length) return output;

    const displayedCollections = this.store.collections.allCollections
      .filter(
        collection =>
          collection.id !== this.collection?.id &&
          collection.label.toLowerCase().includes(this.searchQuery.toLowerCase())
      )
      .sort((a, b) => b.lastInteractedAt.localeCompare(a.lastInteractedAt))
      .slice(0, 5);

    if (displayedCollections.length > 0) {
      output.items.push({
        id: "collections-title",
        kind: MdsDropdownItemKind.Detail,
        text: "Collections",
      });
      displayedCollections.forEach((collection, index) => {
        output.items.push({
          id: `collection-${collection.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: MdsIconKind.Collection,
          label: collection.label,
          className: index + 1 === this.keyboardSelectedIndex ? selectedOptionStyles : undefined,
          onClick: () => {
            actions.addNotesToCollection({ notes: items, collection, store: this.store });
            this.handleConfirm?.();
            this.close();
          },
        });
      });
    }

    if (this.searchQuery.length > 0) {
      output.items.push({
        id: "create-collection-title",
        kind: MdsDropdownItemKind.Detail,
        text: "Create collection",
      });
      output.items.push({
        id: "create-collection-button",
        kind: MdsDropdownItemKind.Button,
        iconKind: MdsIconKind.Plus,
        label: `Collection named ${this.searchQuery}`,
        className:
          this.keyboardSelectedIndex > displayedCollections.length
            ? selectedOptionStyles
            : undefined,
        onClick: () => {
          const collectionId = uuidModule.generate();
          this.store.collections.createCollection({
            collectionId,
            title: this.searchQuery,
          });
          const collectionObservable = this.store.collections.getCollectionObservableById({
            collectionId,
          });
          actions.addNotesToCollection({
            notes: items,
            collection: collectionObservable!,
            store: this.store,
          });
          this.handleConfirm?.();
          this.close();
        },
      });
    }
    return output;
  }

  public open({ noteIds, collection }: { noteIds: string[]; collection?: CollectionObservable }) {
    this.selectedNoteIds = noteIds;
    this.collection = collection;
  }

  public close() {
    this.selectedNoteIds = [];
    this.collection = undefined;
  }

  public setSearchQuery(query: string) {
    this.searchQuery = query;
    this.resetSelectedIndex();
  }

  resetSelectedIndex() {
    this.keyboardSelectedIndex = 1;
  }

  incrementSelectedIndex() {
    const listLength = this.dropdownContentList.items.length;
    const nextItemIndex = (this.keyboardSelectedIndex + 1) % listLength;
    const nextItem = this.dropdownContentList.items[nextItemIndex];
    if (nextItem.kind === MdsDropdownItemKind.Button) {
      this.keyboardSelectedIndex = nextItemIndex;
    } else {
      this.keyboardSelectedIndex = (this.keyboardSelectedIndex + 2) % listLength;
    }
  }

  decrementSelectedIndex() {
    const listLength = this.dropdownContentList.items.length;
    const prevItemIndex = (this.keyboardSelectedIndex - 1 + listLength) % listLength;
    const prevItem = this.dropdownContentList.items[prevItemIndex];
    if (prevItem.kind === MdsDropdownItemKind.Button) {
      this.keyboardSelectedIndex = prevItemIndex;
    } else {
      this.keyboardSelectedIndex = (this.keyboardSelectedIndex - 2 + listLength) % listLength;
    }
  }

  onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = e => {
    switch (e.key) {
      case "ArrowUp": {
        e.preventDefault();
        this.decrementSelectedIndex();
        break;
      }
      case "ArrowDown": {
        e.preventDefault();
        this.incrementSelectedIndex();
        break;
      }
      case "Enter": {
        e.preventDefault();
        const selectedItem = this.dropdownContentList.items[this.keyboardSelectedIndex];
        if (selectedItem.kind === MdsDropdownItemKind.Button) {
          selectedItem.onClick({ itemId: selectedItem.id });
        }
        break;
      }
    }
  };
}

const selectedOptionStyles = css({
  background: mdsColors().grey.x50,
});
