import { FC, useState, useEffect, useCallback, useRef } from "react";
import styled from "@emotion/styled";
import { electronModule } from "@/modules/electron";
import { MdsIconKind } from "@/design-system/components/icon";
import { MdsIconButton, MdsIconButtonSize } from "@/design-system/components/icon-button";
import { observer } from "mobx-react-lite";
import { usePublicAppStore } from "@/store";
import { useEffectOnMount } from "@/domains/react/useEffectOnMount";

interface FindInPageResult {
  matches: number;
  activeMatchOrdinal: number;
  finalUpdate: boolean;
}

export const FindInPageOverlayContent: FC = observer(() => {
  const { publicStore } = usePublicAppStore();
  const [query, setQuery] = useState("");
  const [matchesCount, setMatchesCount] = useState(0);
  const [activeMatchOrdinal, setActiveMatchOrdinal] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const searchTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleFindInPageResult = useCallback((event: unknown, result: FindInPageResult) => {
    const { matches, activeMatchOrdinal } = result;
    setMatchesCount(matches);
    setActiveMatchOrdinal(activeMatchOrdinal);
  }, []);

  useEffectOnMount(() => {
    electronModule.onFindInPageResult({ callback: handleFindInPageResult });
  });

  const handleSearch = useCallback((text: string) => {
    setQuery(text);

    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    searchTimeoutRef.current = setTimeout(() => {
      if (text) {
        electronModule.findInPage({
          text,
        });
      } else {
        electronModule.stopFindInPage({ action: "clearSelection" });
      }
    }, 300);
  }, []);

  const handleNext = useCallback(() => {
    if (query) {
      electronModule.findInPage({ text: query, options: { findNext: true, forward: true } });
    }
  }, [query]);

  const handlePrev = useCallback(() => {
    if (query) {
      electronModule.findInPage({ text: query, options: { findNext: true, forward: false } });
    }
  }, [query]);

  const handleClose = useCallback(() => {
    setQuery("");
    electronModule.stopFindInPage({ action: "clearSelection" });
    publicStore.interface.setIsFindInPageOpen(false);
  }, [publicStore.interface]);

  const handleQueryChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleSearch(e.target.value);
    },
    [handleSearch]
  );

  /**
   * @TODO - Figure out why this keeps losing focus.
   */
  return (
    <Wrapper>
      <LeftWrapper>
        <StyledTextField
          autoFocus
          key="find-in-page-input"
          ref={inputRef}
          placeholder="Find in page"
          value={query}
          onChange={handleQueryChange}
        />
      </LeftWrapper>
      <RightWrapper>
        <MatchCounter>
          {matchesCount > 0 ? `${activeMatchOrdinal} of ${matchesCount}` : "No matches"}
        </MatchCounter>
        <MdsIconButton
          iconKind={MdsIconKind.AngleUp}
          onClick={handlePrev}
          isDisabled={matchesCount === 0}
          size={MdsIconButtonSize.XSmall}
        />
        <MdsIconButton
          iconKind={MdsIconKind.AngleDown}
          onClick={handleNext}
          isDisabled={matchesCount === 0}
          size={MdsIconButtonSize.XSmall}
        />
        <MdsIconButton iconKind={MdsIconKind.Exit} onClick={handleClose} />
      </RightWrapper>
    </Wrapper>
  );
});

// Styled components
const Wrapper = styled.div({
  position: "fixed",
  top: "20px",
  right: "20px",
  background: "#ffffff",
  padding: "10px",
  width: "400px",
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  alignItems: "center",
  justifyContent: "space-between",
  border: "1px solid #e0e0e0",
  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
  zIndex: 9999,
  borderRadius: "8px",
  fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
});

const LeftWrapper = styled.div({
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  alignItems: "center",
  justifyContent: "flex-start",
});

const RightWrapper = styled.div({
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  alignItems: "center",
  justifyContent: "flex-end",
});

const StyledTextField = styled.input``;

const MatchCounter = styled.span({
  marginRight: "10px",
  fontSize: "14px",
  color: "#666",
});
