import { Maybe } from "@/domains/common/types";
import { Uuid } from "@/domains/global/identifiers";
import { INoteObservable, NoteObservable } from "@/store/note";
import { uuidModule } from "@/modules/uuid";
import { NoteModelData } from "@/store/note/types";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { CreateNoteOperation } from "@/store/sync/operations/notes/CreateNoteOperation";
import { DeleteNoteOperation } from "@/store/sync/operations/notes/DeleteNoteOperation";
import { TrashNoteOperation } from "@/store/sync/operations/notes/TrashNoteOperation";
import { SyncModelKind, SyncUpdateValue } from "@/store/sync/types";
import { AppSubStoreArgs } from "@/store/types";
import { action, computed, makeObservable, observable } from "mobx";
import { SearchSuggestion, SearchSuggestionType } from "@/domains/search";

export interface NotesStore {
  allNotes: INoteObservable[];
  createNote: (payload: { noteId: Uuid }) => void;
  getNoteObservableById: (payload: { noteId: Uuid }) => Maybe<INoteObservable>;
}

export class AppStoreNoteStore
  extends BaseSyncModelStore<NoteObservable, NoteModelData>
  implements NotesStore
{
  private searchTitleLimit = 100;

  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.Note, ...injectedDeps });
    makeObservable<this, "searchTitleLimit" | "getSearchableData" | "getSearchableSortKey">(this, {
      searchTitleLimit: observable,

      createSyncModel: false,
      getNoteObservableById: false,

      allNotes: computed,
      allDataForSearch: computed,
      allDataForMention: computed,

      composeNewNote: action,
      createNote: action,
      updateNote: action,
      deleteNote: action,
      trashNote: action,

      getSearchableData: false,
      getSearchableSortKey: false,
    });
  }

  createSyncModel(updateValue: SyncUpdateValue<NoteModelData>): NoteObservable {
    return new NoteObservable({ id: updateValue.model_id, data: updateValue, store: this.store });
  }

  getNoteObservableById = ({ noteId }: { noteId?: Uuid }): Maybe<INoteObservable> => {
    return noteId ? this.get(noteId) : undefined;
  };

  get allNotes(): INoteObservable[] {
    return Array.from(this.pool.values());
  }

  get allDataForSearch(): SearchSuggestion[] {
    return this.getSearchableData(false);
  }

  get allDataForMention(): SearchSuggestion[] {
    return this.getSearchableData(true);
  }

  // ACTIONS
  public composeNewNote = () => {
    const noteId = uuidModule.generate();

    // Delay creation so home doesn't show an untitled note before navigating to the editor.
    setTimeout(() => {
      this.createNote({ noteId });
    }, 0);

    this.store.navigation.goToNote({ noteId, autoFocus: true });
  };

  public createNote({ noteId }: { noteId: Uuid }) {
    new CreateNoteOperation({ store: this.store, payload: { id: noteId } }).execute();
  }

  public updateNote() {
    console.log("[AppStoreNoteStore] updateNote - Not Implemented Yet");
  }

  public deleteNote({ noteId }: { noteId: Uuid }) {
    new DeleteNoteOperation({ store: this.store, payload: { id: noteId } }).execute();
  }

  public trashNote({ noteId }: { noteId: Uuid }) {
    new TrashNoteOperation({ store: this.store, payload: { id: noteId } }).execute();
  }

  private getSearchableData(includeMentionedAt: boolean): SearchSuggestion[] {
    return this.allNotes
      .filter(note => note.isAvailable)
      .map(note => ({
        id: note.id,
        label: note.title.slice(0, this.searchTitleLimit),
        type: SearchSuggestionType.NOTE,
        lastViewedAt: note.lastViewedAt,
        sortKey: this.getSearchableSortKey(note, includeMentionedAt),
      }));
  }

  private getSearchableSortKey(note: INoteObservable, includeMentionedAt: boolean): number {
    let sortKey = note.lastViewedAt || note.receivedAt;
    if (includeMentionedAt) {
      sortKey = note.lastMentionedAt || sortKey;
    }

    return new Date(sortKey || "").getTime();
  }
}
