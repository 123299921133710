import { orderBy } from "lodash-es";
import { action, computed, makeObservable, observable } from "mobx";
import {
  groupLens,
  TimelineWithInboxStatusGroup,
  TimelineWithInboxStatusItem,
} from "@/modules/timeline";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import {
  MdsItemDropdown,
  MdsItemKind,
  MdsItemListRowData,
  MdsItemListRowType,
  MdsItemListSize,
} from "@/design-system/components/item-list/types";
import { generateShortDateString } from "@/domains/date/date";
import { ListStateObservable } from "@/store/pages/ListStateObservable";
import { INoteObservable } from "@/store/note";
import { MdsDropdownItemKind } from "@/design-system/components/dropdown";
import { MdsIconKind } from "@/design-system/components/icon";
import { DeleteNotePermanentlyModalStore } from "@/components/modal/delete-notes-permanently/DeleteNotePermanentlyModalStore";
import { ItemPreviewState } from "@/design-system/components/item-list/rows/item-preview/ItemPreviewState";
import { DateTime } from "luxon";
import { MdsItemListRowFeaturedContent } from "@/design-system/components/item-list/rows/MdsItemListRowFeaturedContent";
import { sortCollectionsForChips } from "@/domains/collections/sortCollectionsForChips";

export class TrashPageStore extends AppSubStore {
  listState: ListStateObservable;
  deleteNotePermanentlyModal: DeleteNotePermanentlyModalStore;
  supportsRestoreListAction = true;
  showConfirmModal = false;

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);

    this.deleteNotePermanentlyModal = new DeleteNotePermanentlyModalStore(injectedDeps, {});
    this.listState = new ListStateObservable({
      ...injectedDeps,
      listStateProvider: this,
      deleteNotePermanentlyModal: this.deleteNotePermanentlyModal,
    });

    makeObservable(this, {
      listState: false,
      deleteNotePermanentlyModal: false,
      supportsRestoreListAction: observable,
      showConfirmModal: observable,
      openConfirmModal: action,
      closeConfirmModal: action,
      emptyTrash: action,
      isEmptyingTrash: computed,
      generateItemDropdown: false,
      itemRows: computed,
      items: computed,
      orderedItemIds: computed,
    });
  }

  openConfirmModal = () => {
    this.showConfirmModal = true;
  };

  closeConfirmModal = () => {
    this.showConfirmModal = false;
  };

  emptyTrash = () => {
    for (const row of this.itemRows) {
      if (row.type !== MdsItemListRowType.Item) continue;
      // TODO: Implement a single action to delete all notes on the backend.
      this.store.notes.deleteNote({ noteId: row.payload.id });
    }

    this.closeConfirmModal();
  };

  get isEmptyingTrash() {
    // Assuming any note deletion is the result of emptyTrash.
    const deleteNoteOperations =
      this.store.sync.actionQueue.operationsByOperationKind.get("DELETE_NOTE");
    if (!deleteNoteOperations) return false;
    return deleteNoteOperations.length > 0;
  }

  generateItemDropdown({ noteObservable }: { noteObservable: INoteObservable }): MdsItemDropdown {
    return {
      items: [
        {
          id: `restore-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: MdsIconKind.Redo,
          label: "Restore this note",
          onClick: () => noteObservable.restoreFromTrash(),
        },
        {
          id: `delete-permanently-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: MdsIconKind.Trash,
          label: "Delete this note forever",
          onClick: () => this.deleteNotePermanentlyModal.open({ notes: [noteObservable] }),
        },
      ],
    };
  }

  get itemRows(): MdsItemListRowData[] {
    const output: MdsItemListRowData[] = [];
    for (const group of this.items) {
      // Add header for each section
      output.push({
        type: MdsItemListRowType.SectionHeader,
        key: group.title,
        payload: { title: group.title },
      });
      // Add each item afterwards
      for (const item of group.items) {
        if (item.kind === "full") {
          const noteObservable = this.store.notes.getNoteObservableById({
            noteId: item.item.id,
          });
          if (!noteObservable) continue;
          output.push({
            type: MdsItemListRowType.Item,
            key: noteObservable.id,
            size: MdsItemListSize.XLarge,
            payload: {
              id: noteObservable.id,
              kind: MdsItemKind.Note,
              createPreviewState: () =>
                new ItemPreviewState({
                  store: this.store,
                  id: item.item.id,
                  kind: MdsItemKind.Note,
                }),
              label: noteObservable.title,
              onClick: () => this.store.navigation.goToNote({ noteId: item.item.id }),
              sharedBy: noteObservable.sharedBy,
              dateLabel: generateShortDateString(DateTime.fromJSDate(item.dateTime)),
              listState: this.listState,
              dropdown: this.generateItemDropdown({ noteObservable }),
              extraRows: [
                {
                  id: `note-${noteObservable.id}-content`,
                  content: () => (
                    <MdsItemListRowFeaturedContent
                      collections={sortCollectionsForChips(
                        noteObservable.collectionList?.allCollections || []
                      )}
                      snippet={
                        noteObservable.collectionList?.allCollections.length
                          ? undefined
                          : [{ text: noteObservable.secondaryTitle || "No additional text" }]
                      }
                    />
                  ),
                },
              ],
            },
          });
        }
      }
    }
    if (output.length) {
      output.push({
        type: MdsItemListRowType.Padding,
        key: "padding",
        payload: { height: 50 },
      });
    }
    return output;
  }

  get items(): TimelineWithInboxStatusGroup[] {
    const epoch = new Date(0).toISOString();
    const items: TimelineWithInboxStatusItem[] = orderBy(
      this.store.notes.allNotes.filter(note => note.isTrashed && !note.isDeleted && note.canAccess),
      note => note.trashedAt || epoch,
      "desc"
    ).map(note => ({
      kind: "full",
      dateTime: new Date(note.trashedAt || epoch),
      item: note,
    }));
    return groupLens(items);
  }

  get orderedItemIds() {
    return this.itemRows.map(row => row.key);
  }
}
