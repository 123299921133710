import { CopilotVotingButtons } from "@/components/copilot/TopicItemVotingButtons";
import { api } from "@/modules/api";
import { toastModule } from "@/modules/toast";
import { getRowForNoteId } from "@/store/note/getRowForNoteId";
import { NoteObservable } from "@/store/note/NoteObservable";
import { RelatedCard } from "@/store/routing/types";
import { SpaceAccountTopicObservable } from "@/store/topics/SpaceAccountTopicObservable";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { action, computed, makeObservable, observable } from "mobx";

enum TopicItemVote {
  None,
  Upvote,
  Downvote,
}

export class CopilotViewStore extends AppSubStore {
  highlightedRowId: string | null = null;
  highlightedTopicId: string | null = null;
  votedIds = new Set<string>();

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);

    makeObservable(this, {
      addToVotedIds: true,
      submitTopicItemFeedback: false,
      submitTopicFeedback: false,
      highlightedTopicId: observable,
      activeItem: computed,
      activeSpaceAccountTopics: computed,
      relatedCards: computed,
      highlightedRowId: observable,
      votedIds: observable,
      setHighlightedRow: action,
    });
  }

  setHighlightedRow(rowId: string | null, topicId: string | null) {
    this.highlightedRowId = rowId;
    this.highlightedTopicId = topicId;
  }

  public get activeItem(): NoteObservable | undefined {
    const noteId = this.store.routing.noteIdParam;
    if (noteId) return this.store.notes.get(noteId);

    return undefined;
  }

  public get activeSpaceAccountTopics(): SpaceAccountTopicObservable[] {
    if (!this.activeItem) return [];
    return this.activeItem.spaceAccountTopics.allTopics.slice(0, 3);
  }

  public get relatedCards(): RelatedCard[] {
    return this.activeSpaceAccountTopics.map(topic => ({
      id: topic.id,
      title: topic.title,
      items: topic.allTopicItems
        .filter(topicItem => topicItem.itemId !== this.activeItem?.id)
        .map(topicItem => {
          const note = topicItem.item;
          if (!note) return null;
          const row = getRowForNoteId({
            dateTime: note?.lastViewedAt,
            inMainPanel: false,
            noteId: note?.id,
            store: this.store,
          });
          if (!row) return null;
          return {
            ...row,
            payload: {
              ...row.payload,
              rightAlignedComponent: (
                <CopilotVotingButtons
                  itemId={row.payload.id}
                  topicItemId={topicItem.id}
                  topicId={topic.id}
                  highlightedItemId={this.highlightedRowId}
                  highlightedTopicId={this.highlightedTopicId}
                  feedbackClass="topic-item"
                />
              ),
            },
          };
        })
        .filter(row => !!row),
    }));
  }

  submitTopicItemFeedback = async (
    voteType: TopicItemVote,
    topicItemId: string,
    additionalComments: string | null
  ) => {
    const userSpaceId = this.store.spaces.myPersonalSpaceId;
    try {
      const response = await api.post("/v2/related-notes/feedback/topic/item/", {
        params: { query: { space_id: userSpaceId } },
        body: {
          space_account_topic_item_id: topicItemId,
          feedback_kind: voteType === TopicItemVote.Upvote ? "UPVOTE" : "DOWNVOTE",
          feedback_comments: additionalComments || "",
        },
      });
      if (response.error) {
        toastModule.triggerToast({
          content: `Failed to submit feedback`,
        });
        return null;
      } else {
        const result = await response.data;
        if (result["feedback_event_id"] !== null) {
          return result["feedback_event_id"];
        }
      }
    } catch (error) {
      console.log("[DEBUG] error", error);
      toastModule.triggerToast({
        content: `Failed to submit feedback`,
      });
      return null;
    }
  };

  submitTopicFeedback = async (
    voteType: TopicItemVote,
    topicId: string,
    additionalComments: string | null
  ) => {
    const userSpaceId = this.store.spaces.myPersonalSpaceId;
    try {
      const response = await api.post("/v2/related-notes/feedback/topic/", {
        params: { query: { space_id: userSpaceId } },
        body: {
          space_account_topic_id: topicId,
          feedback_kind: voteType === TopicItemVote.Upvote ? "UPVOTE" : "DOWNVOTE",
          feedback_comments: additionalComments || "",
        },
      });
      if (response.error) {
        toastModule.triggerToast({
          content: `Failed to submit feedback`,
        });
        return null;
      } else {
        const result = await response.data;
        if (result["feedback_event_id"] !== null) {
          return result["feedback_event_id"];
        }
      }
    } catch (error) {
      toastModule.triggerToast({
        content: `Failed to submit feedback`,
      });
      return null;
    }
  };

  public addToVotedIds(id: string) {
    this.votedIds.add(id);
  }
}
