import { AppStoreNavigationStore } from "@/store/navigation/AppStoreNavigationStore";
import { NavigateFunction } from "react-router";

export const goForward = (store: INavigationStore, navigate: NavigateFunction) => {
  const head = store.forwardStack.pop();

  if (!head) return;

  navigate(1);
  store.virtuosoStateStack.push(undefined);
  store.stack.push(head);
};

export type INavigationStore = Pick<
  AppStoreNavigationStore,
  "stack" | "forwardStack" | "virtuosoStateStack"
>;
