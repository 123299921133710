import table from "@/domains/local-db/account/settings/adapter";
import config from "@/domains/local-db/account/settings/config";
import { CollectionsListPageParams } from "@/store/pages/CollectionsListPageStore/CollectionsListPageStore";
import { CollectionsViewPageParams } from "@/store/pages/CollectionsViewPageStore/CollectionsViewPageStore";
import { NotesListPageParams } from "@/store/pages/NotesListPageStore";
import { SidePanelRoute, SidePanelState, SidePanelTab } from "@/store/routing/types";

export const getSidePanelState = async (): Promise<{
  sidePanelState?: SidePanelState;
  activeTabId?: SidePanelTab;
  tabs?: Map<SidePanelTab, SidePanelRoute[]>;
}> => {
  const [sidePanelStateResponse, activeTabIdResponse, tabsResponse] = await Promise.all([
    table.get(config.SIDE_PANEL_STATE_KEY),
    table.get(config.SIDE_PANEL_ACTIVE_TAB_ID_KEY),
    table.get(config.SIDE_PANEL_TABS_KEY),
  ]);

  const sidePanelStateString = sidePanelStateResponse?.[config.SIDE_PANEL_STATE_KEY];
  const activeTabIdString = activeTabIdResponse?.[config.SIDE_PANEL_ACTIVE_TAB_ID_KEY];
  const tabsString = tabsResponse?.[config.SIDE_PANEL_TABS_KEY];
  const parsedTabs = tabsString ? JSON.parse(tabsString) : [];

  const tabs = new Map<SidePanelTab, SidePanelRoute[]>();
  for (const [tabId, tabRoutes] of parsedTabs) {
    tabs.set(tabId as SidePanelTab, tabRoutes as SidePanelRoute[]);
  }

  return {
    sidePanelState: sidePanelStateString,
    activeTabId: activeTabIdString,
    tabs,
  };
};

export const getNotesListPageParams = async (): Promise<Partial<NotesListPageParams>> => {
  const paramsResponse = await table.get(config.NOTES_LIST_PAGE_PARAMS_KEY);
  const paramsString = paramsResponse?.[config.NOTES_LIST_PAGE_PARAMS_KEY];
  return paramsString ? JSON.parse(paramsString) : {};
};

export const getCollectionsListPageParams = async (): Promise<
  Partial<CollectionsListPageParams>
> => {
  const paramsResponse = await table.get(config.COLLECTIONS_LIST_PAGE_PARAMS_KEY);
  const paramsString = paramsResponse?.[config.COLLECTIONS_LIST_PAGE_PARAMS_KEY];
  return paramsString ? JSON.parse(paramsString) : {};
};

export const getCollectionsViewPageParams = async (
  collectionId: string
): Promise<Partial<CollectionsViewPageParams>> => {
  const paramsResponse = await table.get(config.getCollectionsViewPageParamsKey(collectionId));
  const paramsString = paramsResponse?.[config.COLLECTIONS_VIEW_PAGE_PARAMS_KEY];
  return paramsString ? JSON.parse(paramsString) : {};
};
