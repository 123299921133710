import { clientEnvModule } from "@/modules/client-env";

/**
 * Should match the definition in our `mem-client-desktop` project.
 */
type ElectronAPI = {
  navigateToInternalUrl: (args: { url: string }) => Promise<void>;
  navigateToExternalUrl: (args: { url: string }) => Promise<void>;
  findInPage: (args: { text: string; options?: unknown }) => Promise<void>;
  stopFindInPage: (args: { action: "clearSelection" | "keepSelection" }) => Promise<void>;
  onFindInPageResult: (callback: (event: unknown, result: unknown) => void) => void;
};

export const getElectronAPI = (): ElectronAPI | null => {
  const isDesktop = clientEnvModule.isDesktop();

  if (isDesktop) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (window as any).electronAPI as ElectronAPI;
  }

  return null;
};
