import { ChatMessageStore } from "@/domains/chat/chat-message-store";
import { notesModule } from "@/modules/notes";
import { uuidModule } from "@/modules/uuid";
import { ChatHistory } from "@/store/chat/ChatHistory";
import { ChatMessageObservable } from "@/store/chat/ChatMessageObservable";
import {
  ChatMessageContext,
  ChatMessageContextKind,
  ChatMessageModelData,
  ChatMessageUpsertedSyncUpdateValue,
} from "@/store/chat/types";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { SaveChatMessageOperation } from "@/store/sync/operations/chat/SaveChatMessageOperation";
import { SaveDraftNoteOperation } from "@/store/sync/operations/chat/SaveDraftNoteOperation";
import { SubmitChatMessageOperation } from "@/store/sync/operations/chat/SubmitChatMessageOperation";
import { SubmitChatMessageSyncOperationChatMessageContextValue } from "@/store/sync/operations/types";
import {
  OptimisticSyncUpdate,
  SyncModelKind,
  SyncUpdate,
  SyncUpdateValue,
} from "@/store/sync/types";
import { AppSubStoreArgs } from "@/store/types";
import { sortBy } from "lodash-es";
import { computed, makeObservable, runInAction, action, override } from "mobx";

export class AppStoreChatMessageStore
  extends BaseSyncModelStore<ChatMessageObservable, ChatMessageModelData>
  implements ChatMessageStore
{
  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.ChatMessage, ...injectedDeps });
    makeObservable<this>(this, {
      createSyncModel: false,
      sendNewMessage: action,
      saveChatMessage: action,
      saveDraftNoteSection: action,
      processSyncUpdate: override,
      processLiveSyncUpdate: action,
      sortedMessages: computed,
      lastUserMessage: computed,
      chatHistory: computed,
      unsentMessages: computed,
      lastSystemMessage: computed,
    });
  }

  createSyncModel(data: SyncUpdateValue<ChatMessageModelData>): ChatMessageObservable {
    return new ChatMessageObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }

  get sortedMessages() {
    return sortBy(Array.from(this.pool.values()), message => message?.locallyCreatedAt, "desc");
  }

  get lastUserMessage() {
    return this.sortedMessages.findLast(e => !e.isSystemMessage);
  }

  get lastSystemMessage(): ChatMessageObservable | undefined {
    return this.sortedMessages.findLast(message => message.isSystemMessage);
  }

  get unsentMessages(): ChatMessageObservable[] {
    const result: ChatMessageObservable[] = [];
    for (let i = this.sortedMessages.length - 1; i >= 0; i--) {
      const message = this.sortedMessages[i];
      if (message.isSystemMessage) {
        continue;
      }
      if (message.status === "PROCESSING") {
        result.push(message);
      } else {
        break;
      }
    }

    return result.reverse();
  }

  get chatHistory() {
    const collectionId = this.store.routing.collectionIdParam;
    if (collectionId)
      return new ChatHistory({
        store: this.store,
        allMessages: this.sortedMessages,
        context: {
          kind: ChatMessageContextKind.CollectionDetailView,
          id: collectionId,
          observable: () => this.store.collections.get(collectionId),
        },
      });

    const noteId = this.store.routing.noteIdParam;
    if (noteId)
      return new ChatHistory({
        store: this.store,
        allMessages: this.sortedMessages,
        context: {
          kind: ChatMessageContextKind.NoteDetailView,
          id: noteId,
          observable: () => this.store.notes.get(noteId),
        },
      });

    return new ChatHistory({
      store: this.store,
      allMessages: this.sortedMessages,
    });
  }

  sendNewMessage(message: string, context?: ChatMessageContext, contextStartedAt?: string) {
    this.store.chatConversations.generatePrimaryChatConversationIfNeeded();
    const primaryChatConversation = this.store.chatConversations.primaryChatConversation;
    if (!primaryChatConversation) return;

    const operationContext: SubmitChatMessageSyncOperationChatMessageContextValue = (() => {
      if (context?.kind === ChatMessageContextKind.NoteDetailView) {
        return {
          context_id: uuidModule.generate(),
          started_at: contextStartedAt,
          kind: "NOTE_DETAIL_VIEW",
          value: { schema_version: 1, note_id: context.id },
        };
      }
      if (context?.kind === ChatMessageContextKind.CollectionDetailView) {
        return {
          context_id: uuidModule.generate(),
          started_at: contextStartedAt,
          kind: "COLLECTION_DETAIL_VIEW",
          value: { schema_version: 1, collection_id: context.id },
        };
      }
      return {
        context_id: uuidModule.generate(),
        started_at: contextStartedAt,
        kind: "GLOBAL",
        value: { schema_version: 1 },
      };
    })();

    // TODO: context id shouldn't be regenerated every time
    new SubmitChatMessageOperation({
      store: this.store,
      payload: {
        id: uuidModule.generate(),
        chat_conversation_id: primaryChatConversation.id,
        content: message,
        contexts: [operationContext],
      },
    }).execute();
  }

  async saveChatMessage({ chatMessageId, noteId }: { chatMessageId: string; noteId: string }) {
    const chatMessage = this.get(chatMessageId);
    if (!chatMessage) return;
    const encodedContent = notesModule.convertMdxToEncodedContent(chatMessage.content);
    new SaveChatMessageOperation({
      store: this.store,
      payload: {
        chat_message_id: chatMessageId,
        saved_note_id: noteId,
        encoded_content: encodedContent,
      },
    }).execute();
  }

  async saveDraftNoteSection({
    sectionId,
    chatMessageId,
    noteId,
  }: {
    sectionId: string;
    chatMessageId: string;
    noteId: string;
  }) {
    const chatMessage = this.get(chatMessageId);
    const section = chatMessage?.sectionMap[sectionId];
    if (!chatMessage || !section) return;
    const encodedContent = notesModule.convertMdxToEncodedContent(section.value.content);
    new SaveDraftNoteOperation({
      store: this.store,
      payload: {
        chat_message_id: chatMessageId,
        chat_message_section_id: sectionId,
        saved_note_id: noteId,
        encoded_content: encodedContent,
      },
    }).execute();
  }

  public processSyncUpdate(update: SyncUpdate<ChatMessageModelData>) {
    runInAction(() => {
      super.processSyncUpdate(update);
      this.store.sync.actionQueue.removeAllOptimisticUpdatesByModelId(update.value.model_id);
    });
  }

  async processLiveSyncUpdate(
    sync_operation_id: string,
    value: ChatMessageUpsertedSyncUpdateValue
  ) {
    runInAction(() => {
      // We should only have one pending optimistic update for chat messages + there are too many if we leave them
      this.store.sync.actionQueue.removeAllOptimisticUpdatesByModelId(value.model_id);
      const optimisticUpdate: OptimisticSyncUpdate = {
        sync_id: uuidModule.generate(),
        committed_at: value.model_data.locally_created_at,
        locally_committed_at: value.model_data.locally_created_at,
        kind: "UPSERTED",
        syncOperationId: sync_operation_id,
        value: value,
      };
      this.store.sync.actionQueue.applyOptimisticUpdate(sync_operation_id, optimisticUpdate);
    });
  }
}
