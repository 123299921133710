import { CollectionItemModelData } from "@/store/collection-items/types";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { INoteObservable } from "@/store/note";
import { Uuid } from "@/domains/global/identifiers";
import { computed, makeObservable } from "mobx";
import { WithAppStore } from "@/store/types";
import { SyncUpdateValue } from "@/store/sync/types";
import { BaseSyncModel } from "@/store/sync/BaseSyncModel";
import { ContactObservable } from "@/store/contacts/ContactObservable";

export class CollectionItemObservable extends BaseSyncModel<CollectionItemModelData> {
  constructor({
    id,
    data,
    store,
  }: {
    id: Uuid;
    data: SyncUpdateValue<CollectionItemModelData>;
  } & WithAppStore) {
    super({ id, data, store });
    makeObservable(this, {
      collectionId: computed,
      itemId: computed,
      itemKind: computed,
      collection: computed,
      item: computed,
      addedToCollectionBy: computed,
    });
  }

  // PROPERTIES
  get collectionId() {
    return this.modelData.collection_id;
  }

  get itemId() {
    return this.modelData.item_id;
  }

  get itemKind() {
    return this.modelData.item_kind;
  }

  get addedToCollectionBy(): ContactObservable | undefined {
    const myPersonalSpaceAccountId = this.store.spaceAccounts.myPersonalSpaceAccountId;
    const addedBySpaceAccountId = this.modelData.added_by_space_account_id;
    if (!addedBySpaceAccountId || addedBySpaceAccountId === myPersonalSpaceAccountId) return;
    const contactObservable =
      this.store.contacts.getContactObservableByContactSpaceAccountId(addedBySpaceAccountId);
    return contactObservable;
  }

  get collection(): CollectionObservable | undefined {
    return this.store.collections.getCollectionObservableById({
      collectionId: this.collectionId,
    });
  }

  get item(): INoteObservable | undefined {
    return this.store.notes.getNoteObservableById({ noteId: this.itemId });
  }
}
