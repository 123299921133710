export enum MdsIconKind {
  ArrowLeftToLine = "LEFT_TO_LINE",
  ArrowDownToLine = "DOWN_TO_LINE",
  ArrowRightToLine = "RIGHT_TO_LINE",
  ArrowUpToLine = "UP_TO_LINE",
  ArrowFromRight = "ARROW_FROM_RIGHT",
  ArrowToRight = "ARROW_TO_RIGHT",
  ArrowUpRight = "ARROW_UP_RIGHT",
  ArrowUpArrowDown = "ARROW_UP_ARROW_DOWN",
  ArrowDownTurnLeft = "ARROW_DOWN_TURN_LEFT",
  ArrowRightFromLine = "ARROW_RIGHT_FROM_LINE",
  Play = "PLAY",
  Eye = "EYE",
  EyeSlash = "EYE_SLASH",
  Export = "EXPORT",
  Home = "HOME",
  Group = "GROUP",
  Rocket = "ROCKET",
  Flows = "FLOWS",
  Star = "STAR",
  StarSolid = "STAR_SOLID",
  Tag = "TAG",
  Tasks = "TASKS",
  List = "LIST",
  BellSlash = "BELL_SLASH",
  Cells = "CELLS",
  Checkbox = "CHECKBOX",
  CheckboxSolid = "CHECKBOX_SOLID",
  Check = "CHECK",
  CheckCircleSolid = "CHECK_CIRCLE_SOLID",
  CheckboxEmpty = "CHECKBOX_EMPTY",
  Clock = "CLOCK",
  Copy = "COPY",
  Document = "DOCUMENT",
  Scribble = "SCRIBBLE",
  Globe = "GLOBE",
  Grip = "GRIP",
  Timeline = "TIMELINE",
  Icons = "ICONS",
  Inbox = "INBOX",
  Trash = "TRASH",
  Settings = "SETTINGS",
  AddPerson = "ADD_PERSON",
  LogOut = "LOG_OUT",
  DesktopApp = "DESKTOP_APP",
  PinRight = "PIN_RIGHT",
  UnpinRight = "UNPIN_RIGHT",
  LeftArrow = "LEFT_ARROW",
  RightArrow = "RIGHT_ARROW",
  Calendar = "CALENDAR",
  Clear = "CLEAR",
  Compose = "COMPOSE",
  ComposeInline = "COMPOSE_INLINE",
  Copilot = "COPILOT",
  Message = "MESSAGE",
  MessageSolid = "MESSAGE_SOLID",
  MessageExclamation = "MESSAGE_EXCLAMATION",
  Search = "SEARCH",
  DropdownOpened = "DROPDOWN_OPENED",
  DropdownClosed = "DROPDOWN_CLOSED",
  Envelope = "ENVELOPE",
  EnvelopeOpen = "ENVELOPE_OPEN",
  Envelopes = "ENVELOPES",
  Exit = "EXIT",
  HandPointRibbon = "HAND_POINT_RIBBON",
  BoxFull = "BOX_FULL",
  AngleRight = "ANGLE_RIGHT",
  AngleLeft = "ANGLE_LEFT",
  AngleUp = "ANGLE_UP",
  AngleDown = "ANGLE_DOWN",
  Crown = "CROWN",
  Collection = "COLLECTION",
  More = "MORE",
  Merge = "MERGE",
  Minus = "MINUS",
  Plus = "PLUS",
  Reply = "REPLY",
  Share = "SHARE",
  Shared = "SHARED",
  Sidebar = "SIDEBAR",
  Sparkle = "SPARKLE",
  UniqueSparkle = "UNIQUE_SPARKLE",
  Bolt = "BOLT",
  Upvote = "UPVOTE",
  Downvote = "DOWNVOTE",
  UpvoteSolid = "UPVOTE_SOLID",
  DownvoteSolid = "DOWNVOTE_SOLID",
  Send = "SEND",
  SendAlt = "SEND_ALT",
  Wifi = "WIFI",
  WifiSlash = "WIFI_SLASH",
  CloudSlash = "CLOUD_SLASH",
  LinkSolid = "LINK_SOLID",
  Link = "LINK",
  Loader = "LOADER",
  Lock = "LOCK",
  LockSolid = "LOCK_SOLID",
  Bookmark = "BOOKMARK",
  BookmarkSolid = "BOOKMARK_SOLID",
  Menu = "MENU",
  Redo = "REDO",
  Book = "BOOK",
  Warning = "WARNING",
  Experimental = "EXPERIMENTAL",
  Sort = "SORT",
  SortAlt = "SORT_ALT",
  Help = "HELP",
  Question = "QUESTION",
  Sorted = "SORTED",
  Spinner = "SPINNER",
  Exclamation = "EXCLAMATION",
  Danger = "DANGER",
  Pen = "PEN",
  Ban = "BAN",
  Shapes = "SHAPES",
  User = "USER",
  Image = "IMAGE",
  Gif = "GIF",
  Film = "FILM",
  Code = "CODE",
  Quote = "QUOTE",
  Pdf = "PDF",
  PhoneSms = "PHONE_SMS",
  Import = "IMPORT",
  H1 = "H1",
  H2 = "H2",
  Bold = "BOLD",
  Italic = "ITALIC",
  Underline = "UNDERLINE",
  Strikethrough = "STRIKETHROUGH",
  NumberedList = "NUMBERED_LIST",
  RemoveFormat = "REMOVE_FORMAT",
  Table = "TABLE",
  Paperclip = "PAPERCLIP",
  Dot = "DOT",
  Pipe = "PIPE",
  InfoCircle = "INFO_CIRCLE",
  Notebook = "NOTEBOOK",
  Thumbtack = "THUMBTACK",
  ThumbtackSolid = "THUMBTACK_SOLID",
  Heart = "HEART",
  HeartSolid = "HEART_SOLID",
  GearCode = "GEAR_CODE",
}
