import { moduleFactory } from "@/factories/module";

import { uploadFile } from "@/modules/file/upload";
import { convertEncodedFileContentToBlob } from "@/modules/file/blob";
import { downloadFile } from "@/modules/file/download";

export const fileModule = moduleFactory.create({
  upload: uploadFile,
  download: downloadFile,
  convertEncodedFileContentToBlob: convertEncodedFileContentToBlob,
});
