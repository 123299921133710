import { SyncCustomErrorData } from "@/store/sync/types";
import { BaseExternalOperation } from "@/store/sync/operations/BaseExternalOperation";
import { ExternalOperationKind, IUploadFileExternalOperation } from "@/store/sync/operations/types";
import { AppStore } from "@/store/AppStore";
import { AbstractSyncErrorModalFieldsGenerator } from "@/store/sync/operations/errors/SyncErrorModalFields";
import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { MdsSpacer } from "@/design-system/components/spacer";
import { fileModule } from "@/modules/file";
import { MemCommonEditorFileInfo } from "@mem-labs/common-editor";

export class UploadFileExternalOperation extends BaseExternalOperation<IUploadFileExternalOperation> {
  get operationKind(): ExternalOperationKind {
    return ExternalOperationKind.UPLOAD_FILE;
  }

  async executeExternalOperation(): Promise<void> {
    const fileInfo: MemCommonEditorFileInfo = this.payload.file_info;

    const fileBlob = fileModule.convertEncodedFileContentToBlob({
      encodedFileContent: fileInfo.encodedFileContent,
      fileMimeType: fileInfo.fileMimeType,
    });

    await fileModule.upload({
      fileId: fileInfo.fileId,
      fileBlob,
      fileName: fileInfo.normalizedFileName,
      fileMimeType: fileInfo.fileMimeType,
    });
  }

  get syncErrorModalFieldsGenerator() {
    return this.getSyncErrorModalFieldsGenerator("UNKNOWN");
  }

  private getSyncErrorModalFieldsGenerator(kind: SyncCustomErrorData["kind"]) {
    return (store: AppStore) =>
      new UploadFileExternalOperationModalFieldsGenerator(store, this.id, kind, this);
  }
}

class UploadFileExternalOperationModalFieldsGenerator extends AbstractSyncErrorModalFieldsGenerator {
  protected id: string;
  protected kind: SyncCustomErrorData["kind"];
  protected uploadFileOperation: UploadFileExternalOperation;

  constructor(
    store: AppStore,
    id: string,
    kind: SyncCustomErrorData["kind"],
    uploadFileOperation: UploadFileExternalOperation
  ) {
    super(store);

    this.id = id;
    this.kind = kind;
    this.uploadFileOperation = uploadFileOperation;
  }

  get title() {
    return `Unable to upload file`;
  }

  get message() {
    return `If this error continues, please contact support`;
  }

  downloadFile = () => {
    fileModule.download({
      fileInfo: this.uploadFileOperation.payload.file_info,
    });
  };

  tryAgain = () => {
    this.store.sync.actionQueue.resume();
  };

  get extraActionButtons() {
    return (
      <>
        <MdsButton
          label="Download file"
          variant={MdsButtonVariant.Outlined}
          onClick={this.downloadFile}
        />
        <MdsSpacer />
        <MdsButton
          label="Try again"
          variant={MdsButtonVariant.FilledDark}
          onClick={this.tryAgain}
        />
      </>
    );
  }

  modalActionHandler = async () => {
    this.store.sync.actionQueue.skipAndRevertOperationById(this.id);
  };
}
