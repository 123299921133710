import { convertEncodedFileContentToBlob } from "@/modules/file/blob";
import { MemCommonEditorFileInfo } from "@mem-labs/common-editor";

export const downloadFile = async ({
  fileInfo,
}: {
  fileInfo: MemCommonEditorFileInfo;
}): Promise<void> => {
  const { encodedFileContent, fileMimeType, normalizedFileName } = fileInfo;

  const blob = convertEncodedFileContentToBlob({ encodedFileContent, fileMimeType });

  const url = URL.createObjectURL(blob);

  /**
   * Create a temporary anchor element to trigger the download.
   */
  const a = document.createElement("a");
  a.href = url;
  a.download = normalizedFileName;

  /**
   * Trigger the download.
   */
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  /**
   * Revoke the object URL to free up memory.
   */
  URL.revokeObjectURL(url);
};
