import { GuestAppSubStore, GuestAppSubStoreArgs } from "@/store/types";
import { computed, makeObservable, observable, reaction, runInAction } from "mobx";
import { Maybe } from "@/domains/common/types";
import { INotesViewPageStore } from "@/store/pages/NotesViewPageStore/types";
import { createProxyWithIsDeleted } from "@/hooks/useObjectAfterDeletion";
import { useEffect } from "react";
import { useMatch } from "react-router";
import { appRoutes } from "@/app/router";
import { INoteObservable } from "@/store/note";
import { windowModule } from "@/modules/window";

export class AppStoreGuestNotesViewPageStore
  extends GuestAppSubStore
  implements INotesViewPageStore<INoteObservable>
{
  noteId: string | null = null;
  /** This is a proxy with isDeleted=true after the note is gone. */
  noteObservable: Maybe<INoteObservable>;

  constructor(injectedDeps: GuestAppSubStoreArgs) {
    super(injectedDeps);
    makeObservable(this, {
      goToMention: false,
      myAccount: computed,
      showNotFound: computed,
      useNoteIdFromRoutePath: false,
      noteId: observable,
      noteObservable: observable,
      isUpToDate: computed,
      note: computed,
    });

    reaction(
      () => this.note,
      (maybeNote, previousNote) => {
        const note = (() => {
          if (maybeNote) return maybeNote;
          if (!previousNote || !this.noteId) return;

          return createProxyWithIsDeleted(previousNote);
        })();
        runInAction(() => {
          this.noteObservable = note;
        });
      }
    );
  }

  get isUpToDate() {
    return this.store.sync.isUpToDate;
  }

  get myAccount() {
    return undefined;
  }

  get note(): Maybe<INoteObservable> {
    const noteId = this.noteId;
    if (!noteId) return;
    return this.store.note.getNoteObservableById({ noteId });
  }

  get showNotFound() {
    return false;
  }

  goToMention: INotesViewPageStore["goToMention"] = ({ url }) => {
    windowModule.openInNewTab({ url: new URL(url) });
  };

  useNoteIdFromRoutePath() {
    const noteIdMatch = useMatch(
      appRoutes.notesView({ params: { noteId: ":noteId" }, starPattern: true })
    );
    const maybeNoteIdParam = noteIdMatch?.params.noteId ?? null;
    useEffect(() => {
      runInAction(() => {
        this.noteId = maybeNoteIdParam;
      });
    }, [maybeNoteIdParam]);
  }
}
