export enum LensKind {
  All = "ALL",
  AddedByMe = "ADDED_BY_ME",
  SharedWithMe = "SHARED_WITH_ME",
  Unorganized = "UNORGANIZED",
}

export enum SortByKind {
  LastCreated = "LAST_CREATED",
  LastModified = "LAST_MODIFIED",
  LastViewed = "LAST_VIEWED",
  Alphabetical = "ALPHABETICAL",
}
