import { mdsPanelWidths, mdsSidebarWidths } from "@/design-system/foundations";
import localDb from "@/domains/local-db";
import {
  RelatedCard,
  SidePanelRoute,
  SidePanelRouteType,
  SidePanelState,
  SidePanelTab,
} from "@/store/routing/types";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import {
  action,
  computed,
  makeObservable,
  observable,
  ObservableMap,
  reaction,
  runInAction,
} from "mobx";

export class AppStoreSidePanelStore extends AppSubStore {
  sidePanelFocused: boolean;
  sidePanelState: SidePanelState;
  sidePanelSize = 0;

  activeTabId: SidePanelTab;
  tabs: ObservableMap<SidePanelTab, SidePanelRoute[]>;

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);

    this.sidePanelState = SidePanelState.SPLIT_VIEW;
    this.sidePanelFocused = false;

    this.activeTabId = SidePanelTab.CHAT;
    this.tabs = new ObservableMap<SidePanelTab, SidePanelRoute[]>();

    makeObservable(this, {
      // PERSISTENCE
      initialize: false,
      persistTabs: false,

      // SIDE PANEL STATE
      sidePanelSize: observable,
      sidePanelState: observable,
      canRenderSplitView: computed,
      isMainPanelOpen: computed,
      isSidePanelOpen: computed,
      isSplitView: computed,
      sidePanelWidth: computed,

      // SIDE PANEL FOCUS
      sidePanelFocused: observable,
      setSidePanelFocused: action,

      // TABS
      activeTabId: observable,
      tabs: observable,
      isCopilotActive: computed,
      isChatActive: computed,
      activeRoute: computed,
      activeTabStack: computed,
      defaultTabRoute: computed,

      // ACTIONS
      setSidePanelWidth: action,
      switchTab: action,
      navigateTo: action,
      goToChat: action,
      goToCopilot: action,
      toggle: action,
      open: action,
      close: action,
      goUp: action,
      goToNote: action,
    });

    reaction(
      () => this.sidePanelState,
      state => localDb.settings.setSidePanelState(state)
    );

    reaction(
      () => this.activeTabId,
      activeTab => localDb.settings.setSidePanelActiveTabId(activeTab)
    );
  }

  // SIDE PANEL STATE
  get isSplitView() {
    return this.sidePanelState === SidePanelState.SPLIT_VIEW;
  }

  get isMainPanelOpen() {
    if (!this.canRenderSplitView && this.isSplitView) {
      if (!this.sidePanelFocused) return true;
      else return false;
    } else {
      return this.sidePanelState === SidePanelState.MAIN_PANEL_ONLY || this.isSplitView;
    }
  }

  get isSidePanelOpen() {
    if (!this.canRenderSplitView && this.isSplitView) {
      if (this.sidePanelFocused) return true;
      else return false;
    } else {
      return this.sidePanelState === SidePanelState.SIDE_PANEL_ONLY || this.isSplitView;
    }
  }

  get canRenderSplitView() {
    if (!this.store.interface.dimensionsState.data) return true;
    const { min, gutter } = mdsPanelWidths();
    const { collapsed } = mdsSidebarWidths();
    return this.store.interface.dimensionsState.data?.width > min * 2 + gutter * 2 + collapsed;
  }

  get sidePanelWidth() {
    if (!this.isSidePanelOpen) return "auto";

    return this.sidePanelSize;
  }

  // SIDE PANEL FOCUS
  setSidePanelFocused = (focused: boolean) => {
    this.sidePanelFocused = focused;
  };

  // TABS
  get isCopilotActive() {
    return this.activeRoute.type === SidePanelRouteType.COPILOT;
  }

  get isChatActive() {
    return this.activeRoute.type === SidePanelRouteType.CHAT;
  }

  get activeRoute(): SidePanelRoute {
    return this.activeTabStack[this.activeTabStack.length - 1] || this.defaultTabRoute;
  }

  get activeTabStack(): SidePanelRoute[] {
    return this.tabs.get(this.activeTabId) || [this.defaultTabRoute];
  }

  get defaultTabRoute(): SidePanelRoute {
    return { type: SidePanelRouteType.CHAT };
  }

  // ACTIONS
  setSidePanelWidth = (width: number) => {
    this.sidePanelSize = width;
  };

  switchTab = (tabId: SidePanelTab) => {
    this.activeTabId = tabId;
  };

  navigateTo = (route: SidePanelRoute, { resetStack = false }: { resetStack?: boolean } = {}) => {
    const activeStack = this.tabs.get(this.activeTabId);
    if (!activeStack || resetStack) {
      this.tabs.set(this.activeTabId, [route]);
    } else {
      activeStack.push(route);
    }
    this.persistTabs();
  };

  goToCopilot = (card?: RelatedCard) => {
    this.switchTab(SidePanelTab.COPILOT);
    this.navigateTo({ type: SidePanelRouteType.COPILOT, card }, { resetStack: true });
    this.open();
  };

  goToChat = () => {
    this.switchTab(SidePanelTab.CHAT);
    this.navigateTo({ type: SidePanelRouteType.CHAT }, { resetStack: true });
    this.open();
  };

  goUp = () => {
    const activeStack = this.tabs.get(this.activeTabId);
    if (activeStack && activeStack.length > 1) {
      activeStack.pop();
    } else {
      this.close();
    }
    this.persistTabs();
  };

  toggle = () => {
    if (this.isSplitView) this.close();
    else this.open();
  };

  open = () => {
    if (!this.canRenderSplitView) this.sidePanelFocused = true;
    this.sidePanelState = SidePanelState.SPLIT_VIEW;
  };

  close = () => {
    this.sidePanelFocused = false;
    this.sidePanelState = SidePanelState.MAIN_PANEL_ONLY;
  };

  goToNote = ({ noteId }: { noteId: string }) => {
    this.navigateTo({ type: SidePanelRouteType.NOTE_DETAIL, noteId });
  };

  initialize = async () => {
    const res = await localDb.settings.getSidePanelState();
    runInAction(() => {
      if (res.activeTabId) this.activeTabId = res.activeTabId;
      if (res.sidePanelState) this.sidePanelState = res.sidePanelState;
      if (res.tabs) this.tabs.replace(res.tabs);
    });
  };

  persistTabs = () => {
    localDb.settings.setSidePanelTabs(this.tabs.toJSON());
  };
}
