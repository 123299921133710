import { useAppStore } from "@/store";

import { TopicInfo } from "@/design-system/components/item-list/types";
import styled from "@emotion/styled";
import ColorHash from "color-hash";
import {
  mdsColors,
  mdsFontSizes,
  mdsFontWeights,
  mdsLineHeights,
} from "@/design-system/foundations";
import { CollectionIcon } from "@/components/collection/CollectionIcon";
import { MdsText } from "@/design-system/components/text";
import { css } from "@/domains/emotion";
import { MdsButton, MdsButtonSize, MdsButtonVariant } from "@/design-system/components/button";
import { MdsIconKind } from "@/design-system/components/icon";
import { removeNoteFromCollection } from "@/actions/removeNoteFromCollection";
import { isMemAccount } from "@/store/contacts/isMemAccount";
import { useState } from "react";

interface CollectionPreviewProps {
  collectionTopic: TopicInfo;
  noteId?: string;
}
export const CollectionPreview = ({ collectionTopic, noteId }: CollectionPreviewProps) => {
  const { store } = useAppStore();
  const [tempShowRecentsToMem, setTempShowRecentsToMem] = useState(true);

  const bgColor = bgColorHash.hex(collectionTopic.id);
  const collection = store.collections.getCollectionObservableById({
    collectionId: collectionTopic.id,
  });
  const note = store.notes.getNoteObservableById({ noteId });

  const handleRemove: React.MouseEventHandler = e => {
    if (!note || !collection) return;

    e.stopPropagation();

    removeNoteFromCollection({
      note,
      collection: collection,
      store: store,
    });

    return true;
  };

  const handleOpenInCopilot = () => {
    if (!collection) return;

    // TODO: implement
  };

  if (!collection) return null;

  return (
    <CollectionPreviewContainer>
      <Header style={{ backgroundColor: bgColor }}>
        <div>
          <CollectionIcon collectionId={collection.id} />
        </div>
        <HeaderTitle>
          <MdsText className={titleStyles}>{collection.title}</MdsText>
          <MdsText className={descriptionStyles}>{collection.description}</MdsText>
        </HeaderTitle>
      </Header>
      {isMemAccount(store.account.myAccount) && tempShowRecentsToMem && (
        <Recents onClick={() => setTempShowRecentsToMem(false)}>
          <MdsText className={recentsHeaderStyles}>Recents</MdsText>
          <MdsText className={recentsNoteStyles}>
            List of notes coming soon... <br />
            (visible to Memmers only, click to hide)
          </MdsText>
        </Recents>
      )}
      <CardActions>
        {noteId && (
          <MdsButton
            variant={MdsButtonVariant.TextTertiaryOutlined}
            iconKind={MdsIconKind.Exit}
            label="Remove"
            size={MdsButtonSize.Small}
            onClick={handleRemove}
            // no tooltip for now
          />
        )}
        {/* TODO: bring back once we have a way to open a Collection in Copilot
        <MdsButton
          isDisabled
          variant={MdsButtonVariant.TextTertiaryOutlined}
          iconKind={MdsIconKind.Copilot}
          label="Open in Copilot"
          size={MdsButtonSize.Small}
          onClick={handleOpenInCopilot}
          // no tooltip for now
        />
        */}
      </CardActions>
    </CollectionPreviewContainer>
  );
};

const CollectionPreviewContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing.sm,
  backgroundColor: theme.colors.white,
  minWidth: 290,
  borderRadius: theme.borderRadius.large,
  border: `1px solid ${theme.colors.grey.x25}`,
  overflow: "hidden",
  boxShadow: theme.shadows.hoverCard,
}));

const bgColorHash = new ColorHash({ lightness: 0.92, saturation: 0.45 });

const Header = styled.div(({ theme }) => ({
  padding: `${theme.spacing.smd} ${theme.spacing.md}`,
  display: "flex",
  alignItems: "center",
  gap: theme.spacing.sm,
}));

const HeaderTitle = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing.xxs,
}));

const Recents = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing.xxs,
  padding: `0 ${theme.spacing.md}`,
}));

const CardActions = styled.div(({ theme }) => ({
  display: "flex",
  gap: theme.spacing.sm,
  padding: `0 ${theme.spacing.md} ${theme.spacing.smd} ${theme.spacing.md}`,
}));

const titleStyles = css({
  color: mdsColors().grey.x600,
  fontSize: mdsFontSizes().medium,
  fontWeight: mdsFontWeights().semiBold,
  lineHeight: mdsLineHeights().small,
});

const descriptionStyles = css({
  color: mdsColors().grey.x500,
  textOverflow: "ellipsis",
  overflow: "hidden",
  fontSize: mdsFontSizes().small,
  fontWeight: mdsFontWeights().regular,
  lineHeight: mdsLineHeights().xsmall,
});

const recentsHeaderStyles = css({
  color: mdsColors().grey.x500,
  fontSize: mdsFontSizes().xxsmall,
  fontWeight: mdsFontWeights().semiBold,
  lineHeight: mdsLineHeights().xsmall,
});

const recentsNoteStyles = css({
  color: mdsColors().grey.x400,
  fontSize: mdsFontSizes().xxsmall,
  fontStyle: "italic",
});
