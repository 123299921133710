import styled from "@emotion/styled";
import { inflect } from "inflection";

interface Props {
  count: number;
  itemTypeName?: string;
  description?: string;
}

export function CollectionItemSubtitle({ count, itemTypeName, description }: Props) {
  return <Wrapper>{getCollectionItemSubtitle(count, itemTypeName, description)}</Wrapper>;
}

const Wrapper = styled.div(({ theme }) => ({
  color: theme.colors.grey.x500,
  fontSize: theme.fontSizes.xsmall,
  lineHeight: theme.lineHeights.xxsmall,
}));

export const getCollectionItemSubtitle = (
  count: number,
  itemTypeName?: string,
  description?: string
) => {
  if (count === 0) {
    if (description) {
      return description;
    }

    return `No ${inflect(itemTypeName ?? "item", 0)}`;
  }

  const countText = `${count} ${inflect(itemTypeName ?? "item", count)}`;

  if (!description) {
    return countText;
  }

  return `${countText} • ${description}`;
};
