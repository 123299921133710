import { NoteChip } from "@/components/chips/NoteChip";
import { MdsIconKind } from "@/design-system/components/icon";
import { MdsIconButton } from "@/design-system/components/icon-button";
import { MdsItemListRowFeaturedContent } from "@/design-system/components/item-list/rows/MdsItemListRowFeaturedContent";
import { MdsModal } from "@/design-system/components/modal/MdsModal";
import { mdsMediaQueries } from "@/design-system/foundations";
import { sortCollectionsForChips } from "@/domains/collections/sortCollectionsForChips";
import { INoteObservable } from "@/store/note";
import { truncate } from "lodash-es";
import styled from "@emotion/styled";
import { generateRelativePrepositionAdverbialDateString } from "@/domains/date/date";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  note: INoteObservable;
}

export const AboutNoteModal = ({ isOpen, onClose, note }: Props) => {
  const collections = note?.collectionList?.allCollections || [];
  const hasCollections = collections.length > 0;

  return (
    <MdsModal rootElementId="root" isModalOpen={isOpen} handleCloseModal={onClose}>
      <ModalBodyWrapper>
        <ModalTitlebar>
          <Title>
            About <NoteChip inline title={truncate(note.title, { length: 50 })} />
          </Title>
          <MdsIconButton iconKind={MdsIconKind.Exit} onClick={onClose} />
        </ModalTitlebar>
        <MetaData
          label="Created by"
          value={`${note.primaryOwner.profileDisplayName} ${generateRelativePrepositionAdverbialDateString(note.createdAt)}`}
        />
        {/* TODO: show who last edited this note when we have that data available
            <MetaData label="Last edited by" value={note.createdAt} /> 
        */}
        {hasCollections ? (
          <MetaData
            label="Collections"
            value={
              <MdsItemListRowFeaturedContent
                collections={sortCollectionsForChips(collections)}
                itemId={note.id}
                showAll
              />
            }
          />
        ) : null}
      </ModalBodyWrapper>
    </MdsModal>
  );
};

interface MetaDataProps {
  label: string;
  value: React.ReactNode;
}
const MetaData = ({ label, value }: MetaDataProps) => {
  return (
    <MetaDataWrapper>
      <MetaDataLabel>{label}</MetaDataLabel>
      <MetaDataValue>{value}</MetaDataValue>
    </MetaDataWrapper>
  );
};

// TODO: We have this wrapper component in a lot of modals.
// Let's move it to MDS and match the dialog component in Figma
// https://www.figma.com/design/ZEgqtUJHh5ksFWQ7Nq0P2u/Mem-Design-System?node-id=7826-43214&m=dev
const ModalBodyWrapper = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  maxHeight: "85dvh",
  minWidth: "90dvw",
  maxWidth: "90dvw",
  gap: theme.spacing.md,
  padding: theme.spacing.md,
  [mdsMediaQueries().rules.tablet]: {
    minWidth: "500px",
  },
  [mdsMediaQueries().rules.desktop]: {
    padding: theme.spacing.lg,
    minWidth: "650px",
  },
}));

const ModalTitlebar = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  gap: theme.spacing.sm,
}));

const Title = styled.h2(({ theme }) => ({
  fontSize: theme.fontSizes.large,
  fontWeight: theme.fontWeights.bold,
  lineHeight: "1rem",
  margin: 0,
}));

const MetaDataWrapper = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing.xs,
}));

const MetaDataLabel = styled.div(({ theme }) => ({
  color: theme.colors.grey.x600,
  fontSize: theme.fontSizes.small,
  fontWeight: theme.fontWeights.medium,
  lineHeight: theme.lineHeights.xsmall,
}));

const MetaDataValue = styled.div(({ theme }) => ({
  color: theme.colors.grey.x600,
  fontSize: theme.fontSizes.small,
  fontWeight: theme.fontWeights.regular,
  lineHeight: theme.lineHeights.xsmall,
}));
